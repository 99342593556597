import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor";
import { Autocomplete } from "@mui/lab";
import Alert from "@mui/lab/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as rateApi from "../../api/rateApi";
import { customerCodeFormatter } from "../../common/customerCodeFormatter";
import { getFuelTariffs } from "../../common/getFuelTariff";
import { stateList } from "../../common/stateListAbbreviation";
import * as customerActions from "../../redux/actions/customerActions";
import * as terminalActions from "../../redux/actions/terminalActions";
import AliasNew from "../Alias/AliasNew";
import PlaceSearch from "../Google/PlaceSearch";
import TruckArrow from "../Logo/TruckIconDark.png";
import RateNew from "../ratemanagement/RateNew";
import RateManagementResults from "./RateManagementResults";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
   secondaryHeader: {
      borderBottom: "1px solid #2F3136",
      marginTop: "2rem",
      zIndex: "3",
      width: "100%",
   },
   layoutMain: {
      position: "fixed",
      width: "inherit",
      height: "100%",
   },
   layoutBody: {
      position: "absolute",
      height: "calc(100% - 9rem)",
      width: "100%",
      overflowY: "scroll",
   },
   layoutBody2: {
      position: "absolute",
      height: "calc(100% - 12.5rem)",
      width: "100%",
      overflowY: "scroll",
   },
   search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      border: "1px solid black",
      backgroundColor: "#ffff",
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "30%",
      float: "right",
      [theme.breakpoints.up("sm")]: {
         marginLeft: theme.spacing(3),
         width: "auto",
      },
   },
   searchIcon: {
      color: "black",
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
   },
   save: {
      backgroundColor: "#002D72",
      float: "right",
      color: "white",
      marginRight: "1rem",
      "&:hover": {
         backgroundColor: "#010440",
      },
   },
   back: {
      color: "white",
      backgroundColor: "#2F3136",
      float: "right",
      marginRight: "1rem",
      "&:hover": {
         backgroundColor: "black",
      },
   },
   inputRoot: {},
   inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
         width: "20ch",
      },
   },
   mainControl: {
      [theme.breakpoints.up("sm")]: {
         width: `calc(100% - ${drawerWidth}px)`,
         marginLeft: drawerWidth,
      },
      height: "100%",
      paddingTop: "85px",
   },
   destSearch: {
      height: "2.2rem",
      width: "-webkit-fill-available",
   },
   gridSearchLayout: {
      textAlign: "center",
   },
   listDrawer: {
      width: "300px",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
   },
}));

const RateManagementSearch = (props) => {
   var history = useHistory();
   var handleError = useErrorHandler();
   const classes = useStyles();

   let [loading, setLoading] = useState(false);
   let [readOnly, setReadOnly] = useState(true);
   let [noAccess, setNoAccess] = useState(true);
   let [searchResults, setSearchResults] = useState([]);
   let [selectedCustomer, setSelectedCustomer] = useState(null);
   let [noResults, setNoResults] = useState(false);
   let [noResultText, setNoResultText] = useState('Please reset the search and try again');
   let [openDrawer, setOpenDrawer] = useState(false);
   let [exportingRates, setExportingRates] = useState(false);
   let [newAliasModal, setNewAliasModal] = useState(false);
   let [newRateModal, setNewRateModal] = useState(false);
   let [showAlert, setShowAlert] = useState(false);
   let [alertMessage, setAlertMessage] = useState(null);
   let [alertType, setAlertType] = useState("");
   let [view, setView] = useState("");

   let [search, setSearch] = useState([
      {
         selectedTerminal: null,
         selectedCustomer: "",
         selectedDestination: {
            city: null,
            state: null,
            zip: null,
         },
      },
   ]);

   useEffect(() => {
      props.getTerminals();
      props.getCustomers();

      var curUserRoute = props.user.security.routes.filter((rt) => rt.module === "ratemanagement");
      var userReadOnly = true;
      var userNoAccess = true;

      if (!props.user.security.admin) {
         if (curUserRoute.length > 0) {
            switch (curUserRoute[0].permission) {
               case "readWrite":
                  userReadOnly = false;
                  userNoAccess = false;
                  break;
               case "readOnly":
                  userReadOnly = true;
                  userNoAccess = false;
                  break;
               case "none":
                  userReadOnly = true;
                  userNoAccess = true;
                  break;
            }
         } else {
            userReadOnly = true;
            userNoAccess = true;
         }
      } else {
         userReadOnly = false;
         userNoAccess = false;
      }

      setReadOnly(userReadOnly);
      setNoAccess(userNoAccess);
   }, []);

   ////////////////on change events///////////////////////////////////////
   function onDestinationChange(event, values, index) {
      try {
         //need to check to see how many array items were returned in google api results
         var destination = {
            city: null,
            state: null,
            zip: null,
            place_id: null,
         };

         if (values) {
            //input defaults
            destination.city = values.terms[0].value;

            if (
               values.terms[1].value.length > 2 &&
               values.terms[1].value.toUpperCase() !== "USA" &&
               values.terms[1].value.toUpperCase() !== "US"
            ) {
               //See if terms[1] is state or space in city name.
               let state = _.filter(stateList, (x) => x.name.toUpperCase() === values.terms[1].value.toUpperCase());
               let abbreviation = _.filter(
                  stateList,
                  (x) => x.abbreviation.toUpperCase() === values.terms[1].value.toUpperCase(),
               );

               if (state.length > 0 || abbreviation.length > 0) {
                  if (state.length > 0) {
                     destination.state = state[0].abbreviation;

                     if (
                        values.terms[2].value.toUpperCase() !== "USA" &&
                        values.terms[2].value.toUpperCase() !== "US"
                     ) {
                        destination.zip = values.terms[2].value;
                     } else if (values.terms.length >= 4) {
                        if (
                           values.terms[3].value.toUpperCase() !== "USA" &&
                           values.terms[3].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[3].value;
                        }
                     }
                  } else {
                     destination.state = abbreviation[0].abbreviation;

                     if (
                        values.terms[2].value.toUpperCase() !== "USA" &&
                        values.terms[2].value.toUpperCase() !== "US"
                     ) {
                        destination.zip = values.terms[2].value;
                     } else if (values.terms.length >= 4) {
                        if (
                           values.terms[3].value.toUpperCase() !== "USA" &&
                           values.terms[3].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[3].value;
                        }
                     }
                  }
               } else {
                  destination.city += " " + values.terms[1].value;

                  if (values.terms[2].value.length > 2) {
                     destination.city += " " + values.terms[2].value;

                     destination.state = values.terms[3].value;

                     if (values.terms.length >= 5) {
                        if (
                           values.terms[4].value.toUpperCase() !== "USA" &&
                           values.terms[4].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[4].value;
                        }
                     }
                  } else {
                     destination.state = values.terms[2].value;

                     if (values.terms.length >= 5) {
                        destination.zip = values.terms[3].value;
                     }
                  }
               }
            } else {
               if (values.terms[1].value.toUpperCase() !== "USA" && values.terms[1].value.toUpperCase() !== "US") {
                  destination.state = values.terms[1].value;
               }

               //if searched by zip, then it will appear with 4 items in array, last one always being country
               if (values.terms.length >= 4) {
                  destination.zip = values.terms[2].value;
               }
            }

            destination.place_id = values.place_id;
         }

         var newSearch = _.cloneDeep(search);
         newSearch[index].selectedDestination = destination;

         setSearch(newSearch);
      } catch (err) {
         handleError(err);
      }
   }

   function onTerminalChange(event, values, index) {
      try {
         var newSearch = _.cloneDeep(search);
         newSearch[index].selectedTerminal = values;

         setSearch(newSearch);
      } catch (err) {
         handleError(err);
      }
   }

   ///////////search controls///////////////////////////////////
   function onSearchClicked(isNew = false) {
      //loop through search queries to make sure both terminals and destinations are filled in
      var inValidSearches = _.filter(
         search,
         (query) =>
            query.selectedTerminal === null ||
            (query.selectedDestination.city === null &&
               query.selectedDestination.state === null &&
               query.selectedDestination.zip === null),
      );

      if (inValidSearches.length > 0) {
         openAlertMessage("Please enter a terminal and destination.", "warning");
         return;
      }

      //shows loading progress bar
      setLoading(true);

      //loop through search queries to create properly formatted searchquery object for the api
      var newMultiSearchResults = search.map((query) => {
         return new rateApi.SearchQuery(
            selectedCustomer ? selectedCustomer._id : null,
            query.selectedTerminal._id,
            query.selectedDestination,
            false,
         );
      });

      rateApi
         .searchRates(newMultiSearchResults)
         .then((resp) => {
            //First assign the fuel tariff up front.
            return getFuelTariffs(resp, props.fuelTariffs, props.customers, selectedCustomer, props.terminals);
         })
         .then((resp) => {
            //Next set full search results.
            if (!props.user.security.admin) {
               resp.rates = _.filter(resp.rates, (x) => (x?.rateInfo?.base_rate + x?.rateInfo?.tolls + x?.rateInfo?.bobtail) > 0);
               setNoResultText('Rate not available, please reach out to quote@containerport.com');
            }
            resp.rates = _.orderBy(resp.rates, ["customerTariff"]);

            setSearchResults(resp.rates);

            setNoResults(resp.rates.length !== 0 ? false : true);
            setLoading(false);
            return true;
         })
         .catch((err) => {
            openAlertMessage(`Search Failed: ${err}`, "error");
            setLoading(false);
            return false;
         });
   }

   function additionalSearch() {
      try {
         var newSearch = _.cloneDeep(search);
         var newSearchObj = {
            selectedTerminal: null,
            selectedCustomer: "",
            selectedDestination: {
               city: null,
               state: null,
               zip: null,
            },
         };

         newSearch.push(newSearchObj);
         setSearch(newSearch);
      } catch (err) {
         handleError(err);
      }
   }

   function deleteSearch(index) {
      try {
         var newSearch = _.cloneDeep(search);
         newSearch.splice(index, 1);

         setSearch(newSearch);
      } catch (err) {
         handleError(err);
      }
   }

   function resetSearch() {
      try {
         var newSearchObj = {
            selectedTerminal: null,
            selectedCustomer: "",
            selectedDestination: {
               city: null,
               state: null,
               zip: null,
            },
         };

         setNoResults(false);
         setSearch([newSearchObj]);
         setSearchResults([]);
      } catch (err) {
         handleError(err);
      }
   }

   function newRateClick() {
      try {
         setOpenDrawer(false);
         setNewRateModal(true);
      } catch (err) {
         handleError(err);
      }
   }

   async function exportRates() {
      setExportingRates(true);
      let newMultiSearchResults = search.map((query) => {
         return new rateApi.SearchQuery(
            selectedCustomer ? selectedCustomer._id : null,
            query.selectedTerminal._id,
            query.selectedDestination,
            false,
         );
      });
      await rateApi
         .exportRates(
            null,
            null,
            props.user._id,
            null,
            'Rate',
            newMultiSearchResults
         )
         .then(() => {
            //success
            openAlertMessage(
               "Rate Search Export has started. You will receive a notification and email once it has completed",
               "success",
            );

            setExportingRates(false);
         })
         .catch((err) => {
            openAlertMessage(`${err}`, "error");
            setExportingRates(false);
         });
   }

   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   function closeAlertMessage() {
      setShowAlert(false);
   }

   function breadcrumbOnClick(path) {
      if (path) {
         history.push(path);
      }
   }

   return (
      <>
         {loading && <LinearProgress />}
         {noAccess ? (
            <></>
         ) : (
            <>
               <div id={"rateManagementSearch"} className={classes.layoutMain}>
                  <div
                     id={"rateManagementSearchHeader"}
                     className={view === "details" ? null : classes.secondaryHeader}
                  >
                     {view === "details" ? (
                        <></>
                     ) : (
                        <>
                           <Grid container direction="rows" justifyContent="space-between" alignItems="center">
                              <Grid item xs={5}>
                                 <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                                    <Link
                                       color="inherit"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => breadcrumbOnClick("/ratemanagement")}
                                    >
                                       <h3 style={{ fontWeight: "500" }}>{"Rate Management"}</h3>
                                    </Link>
                                 </Breadcrumbs>
                              </Grid>
                              <Grid item xs={3}>
                                 <Autocomplete
                                    id="combo-box-customer"
                                    options={props.customers}
                                    onChange={(e, values) => setSelectedCustomer(values)}
                                    value={selectedCustomer}
                                    getOptionLabel={(option) => {
                                       return customerCodeFormatter(option.code) + " | " + option.name;
                                    }}
                                    style={{ backgroundColor: "white" }}
                                    renderInput={(params) => (
                                       <TextField {...params} label="Select Customer" variant="outlined" />
                                    )}
                                    autoSelect={true}
                                 />
                              </Grid>
                              <Grid item xs={1}>
                                 <Tooltip title="Reset Search" arrow>
                                    <IconButton
                                       style={{ width: "max-content", marginLeft: "1rem" }}
                                       variant="contained"
                                       color="primary"
                                       onClick={resetSearch}
                                    >
                                       <YoutubeSearchedForIcon style={{ fontSize: "2rem" }}></YoutubeSearchedForIcon>
                                    </IconButton>
                                 </Tooltip>
                              </Grid>
                              <Grid item xs={3}>
                                 <Tooltip title="Export Rates Results" arrow>
                                    <IconButton
                                       style={{ marginRight: "1rem", float: "right" }}
                                       variant="contained"
                                       color="primary"
                                       onClick={exportRates}
                                       disabled={props.user.security.admin && searchResults.length > 0 ? false : true}
                                    >
                                       {exportingRates ? (
                                          <CircularProgress size={32} color="success" />
                                       ) : (
                                          <ImportExportIcon style={{ fontSize: "2rem" }} />
                                       )}
                                    </IconButton>
                                 </Tooltip>
                                 <Tooltip title="New Rate" arrow>
                                    <IconButton
                                       style={{ float: "right" }}
                                       variant="contained"
                                       color="primary"
                                       onClick={newRateClick}
                                       disabled={props.user.security.admin || readOnly !== true ? false : true}
                                    >
                                       <AddIcon style={{ fontSize: "2rem" }} />
                                    </IconButton>
                                 </Tooltip>
                              </Grid>
                           </Grid>
                        </>
                     )}

                     <Collapse in={showAlert}>
                        <Alert
                           style={{ color: "#FFFFFF" }}
                           variant="filled"
                           severity={alertType ? alertType : "success"}
                           action={
                              <IconButton
                                 aria-label="close"
                                 variant="contained"
                                 color="white"
                                 size="small"
                                 onClick={() => {
                                    {
                                       closeAlertMessage();
                                    }
                                 }}
                              >
                                 <CloseIcon fontSize="inherit" />
                              </IconButton>
                           }
                        >
                           {alertMessage}
                        </Alert>
                     </Collapse>
                  </div>
                  <div
                     id={"rateManagementSearchBody"}
                     className={
                        view === "details" ? null : showAlert === true ? classes.layoutBody2 : classes.layoutBody
                     }
                  >
                     <Grid container direction={"row"} justifyContent="space-between" alignItems="center">
                        <Grid item xs={12}>
                           {_.some(searchResults, (res) => true) ? (
                              <RateManagementResults
                                 rates={searchResults}
                                 onSearchClicked={onSearchClicked}
                                 selectedCustomer={selectedCustomer}
                                 openAlertMessage={openAlertMessage}
                                 setSearchResults={setSearchResults}
                                 view={view}
                                 setView={setView}
                                 details
                                 readOnly={readOnly}
                              />
                           ) : // if no results is true, show 0 count result with reset
                              noResults ? (
                                 <>
                                    <Paper elevation={3} style={{ padding: "3%" }}>
                                       <h2 style={{ marginLeft: "1.5rem" }}>Search Results</h2>
                                       <p style={{ marginLeft: "2.5rem", marginBottom: "1rem" }}>
                                          <i>{noResultText}</i>
                                       </p>
                                       {search.map((srch, index) => {
                                          return (
                                             <>
                                                <p style={{ marginLeft: "2.5rem" }}>
                                                   No search results for <b>{srch.selectedTerminal.name}</b> &nbsp;{" "}
                                                   <img src={TruckArrow}></img> &nbsp;{" "}
                                                   <b>
                                                      {srch.selectedDestination.city +
                                                         ", " +
                                                         srch.selectedDestination.state}
                                                   </b>
                                                </p>
                                             </>
                                          );
                                       })}
                                    </Paper>
                                 </>
                              ) : (
                                 <TableContainer component={Paper}>
                                    <Table>
                                       <TableBody>
                                          {search.map((srch, index) => {
                                             return (
                                                <TableRow key={`tr-${index}`}>
                                                   <TableCell align="center" style={{ width: "475px" }}>
                                                      <Autocomplete
                                                         id="combo-box-terminal"
                                                         options={_.filter(props.terminals, (x) => x.active === true)}
                                                         getOptionLabel={(option) => {
                                                            return option.code + " | " + option.name;
                                                         }}
                                                         onChange={(e, values) => onTerminalChange(e, values, index)}
                                                         value={srch.selectedTerminal}
                                                         renderInput={(params) => (
                                                            <TextField
                                                               {...params}
                                                               label="Select Terminal"
                                                               variant="outlined"
                                                            />
                                                         )}
                                                         autoSelect={true}
                                                      />
                                                   </TableCell>
                                                   <TableCell align="center">
                                                      <img src={TruckArrow}></img>
                                                   </TableCell>
                                                   <TableCell align="center" style={{ width: "475px" }}>
                                                      <PlaceSearch
                                                         onChange={(e, values) => onDestinationChange(e, values, index)}
                                                         destination={
                                                            (srch.selectedDestination.city, srch.selectedDestination.state)
                                                               ? srch.selectedDestination.city +
                                                               ", " +
                                                               srch.selectedDestination.state
                                                               : null
                                                         }
                                                         style={{ maxWidth: "450px" }}
                                                      />
                                                   </TableCell>
                                                   <TableCell align="right">
                                                      <Tooltip title="Remove Row" arrow>
                                                         <IconButton
                                                            color="secondary"
                                                            aria-label="delete"
                                                            onClick={() => deleteSearch(index)}
                                                         >
                                                            <DeleteForeverIcon />
                                                         </IconButton>
                                                      </Tooltip>
                                                   </TableCell>
                                                </TableRow>
                                             );
                                          })}
                                       </TableBody>
                                       <div
                                          style={{
                                             position: "absolute",
                                             width: "100%",
                                             paddingRight: "1%",
                                             paddingTop: "1%",
                                          }}
                                       >
                                          <Tooltip title="Add Row" arrow>
                                             <Fab
                                                style={{ marginLeft: "40%" }}
                                                color="primary"
                                                size="small"
                                                aria-label="add"
                                                onClick={() => additionalSearch()}
                                                disabled={loading}
                                             >
                                                <AddIcon />
                                             </Fab>
                                          </Tooltip>
                                          <Tooltip title="Search" arrow>
                                             <IconButton
                                                style={{ float: "right", margintop: "-1rem" }}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => onSearchClicked(true)}
                                                disabled={loading}
                                             >
                                                <Search style={{ fontSize: "2rem", textAlign: "center" }} />
                                             </IconButton>
                                          </Tooltip>
                                       </div>
                                    </Table>
                                 </TableContainer>
                              )}
                        </Grid>
                     </Grid>
                  </div>
               </div>
               <AliasNew open={newAliasModal} handleClose={() => setNewAliasModal(!newAliasModal)} />
               <RateNew
                  open={newRateModal}
                  handleClose={() => setNewRateModal(!newRateModal)}
                  openAlertMessage={openAlertMessage}
               />
            </>
         )}
      </>
   );
};

function mapStateToProps(state) {
   return {
      terminals: state.terminal.terminals || [],
      customers: state.customer !== null ? state.customer.customer : [],
      fuelTariffs: state.fuel.tariff || [],
      user: state.user.currentUser,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getTerminals: () => dispatch(terminalActions.getTerminals()),
      getCustomers: () => dispatch(customerActions.getCustomers()),
   };
}

RateManagementSearch.propTypes = {
   user: PropTypes.object.isRequired,
   customers: PropTypes.array.isRequired,
   fuelTariffs: PropTypes.array.isRequired,
   terminals: PropTypes.array.isRequired,
   getCustomers: PropTypes.func.isRequired,
   getTerminals: PropTypes.func.isRequired,
};

RateManagementSearch.defaultProps = {
   user: {},
   customers: [],
   fuelTariffs: [],
   terminals: [],
   getCustomers: () => {
      return;
   },
   getTerminals: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(RateManagementSearch);
