// #region Imports
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import GetAppIcon from "@mui/icons-material/GetApp";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NoteAddRoundedIcon from '@mui/icons-material/NoteAddRounded';
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { Autocomplete } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { green } from "@mui/material/colors";
import { makeStyles, withStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { saveAs } from "file-saver";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as applicationApi from "../../api/applicationApi";
import * as orderApi from "../../api/orderApi";
import * as rateApi from "../../api/rateApi";
import { _handleObjectProperty } from "../../common/convert";
import { customerCodeFormatter } from "../../common/customerCodeFormatter";
import { getAccessorialTariffs } from "../../common/getAccessorialTariff";
import { getFuelTariffs } from "../../common/getFuelTariff";
import { stateList } from "../../common/stateListAbbreviation";
import "../../styles/truck.css";
import PlaceSearch from "../Google/PlaceSearch";
import containerLogo from "../Logo/ContainerPort-Logo-white.png";
import TruckArrow from "../Logo/TruckIconDark.png";
import TruckArrowWhite from "../Logo/TruckWhite.png";
import AccessorialVerbiage from './AccessorialVerbiage';
import OrdersNewEmail from "./OrdersNewEmail";
// #endregion
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from "@mui/material/LinearProgress";
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import defaultDayjs from "dayjs";
import customParseFormatPlugin from "dayjs/plugin/customParseFormat";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import localizedFormatPlugin from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import * as React from 'react';

defaultDayjs.extend(customParseFormatPlugin);
defaultDayjs.extend(localizedFormatPlugin);
defaultDayjs.extend(isBetweenPlugin);
defaultDayjs.extend(utc);
const adapter = new AdapterDayjs({ instance: defaultDayjs });

const OrdersNew = (props) => {
   const [tabValue, setTabValue] = React.useState('1');
   const handleChangeTabValue = (event, newValue) => {
      setTabValue(newValue);
   };
   const [expandedValidity, setExpandedValidity] = React.useState(false);
   const handleChangeValidity = (panel) => (event, isExpanded) => {
      if (props.user.security.admin) {
         setExpandedValidity(isExpanded ? panel : false);
      }
   };
   // #region Variables
   var history = useHistory();
   var handleError = useErrorHandler();
   let [orderStatuses, setOrderStatuses] = useState([]);
   let [selectedOrderStatus, setSelectedOrderStatus] = useState(null);
   let [loading, setLoading] = useState(true);
   let [showAlert, setShowAlert] = useState(false);
   let [alertMessage, setAlertMessage] = useState(null);
   let [alertType, setAlertType] = useState("");
   let [showDetails, setShowDetails] = useState(false);
   let [searchResults, setSearchResults] = useState(null);
   let [ordersNewEmailModal, setOrdersNewEmailModal] = useState(false);
   let [saveAndSendLoading, setSaveAndSendLoading] = useState(false);
   let [saveLoading, setSaveLoading] = useState(false);
   let [saveAndDownloadLoading, setSaveAndDownloadLoading] = useState(false);
   let [savePreviewLoading, setSavePreviewLoading] = useState(false);
   let [buttonClicked, setButtonClicked] = useState(false);
   let [newOrderResponse, setNewOrderResponse] = useState(null);
   let [quoteTitle, setQuoteTitle] = useState("");
   const [readOnly, setReadOnly] = useState(false);
   const [userDiscountRate, setUserDiscountRate] = useState(props.user.security.discountRatePercentage);
   const [accessorialComment, setAccessorialComment] = useState([]);
   const [overrideAccessorialComment, setOverrideAccessorialComment] = useState([]);
   const [validTill, setValidTill] = useState(null);
   const [validFrom, setValidFrom] = useState(null);
   const [open, setOpen] = useState(false);
   const arrOfField = ['cpm', 'ppr', 'base_rate'];
   const handleCloseQuote = () => {
      discardQuote()
      setOpen(false);
   };

   let [newOrder, setNewOrder] = useState({
      _id: null,
      add_date: null,
      validFrom: null,
      validTill: null,
      items: [
         {
            rate: {
               _id: null,
               terminal_id: null,
               customer_id: null,
               original_customer_id: null,
               destination: {
                  _id: null,
                  city: null,
                  state: null,
                  zip: null,
               },
               rateInfo: {
                  _id: null,
                  miles: null,
                  tolls: null,
                  cost: null,
                  base_rate: null,
                  bobtail: null,
                  lane_type: null,
                  service: null,
                  shipment_type: null,
                  add_date: null,
                  startDate: null,
                  endDate: null,
               },
            },
            customer_id: null,
            terminal_id: null,
            fuelTariff: {
               tariffInfo: {
                  ppr: null,
                  cpm: null,
               },
            },
            accessorialTariff: {
               _id: null,
               accessorialGuide: [
                  {
                     _id: null,
                     name: null,
                     pdfVerbiage: null,
                     value: null,
                  },
               ],
            },
            overrides: {
               base_rate: null,
               cpm: null,
               ppr: null,
               accessorialGuide: [],
            },
            _id: null,
            expanded: null,
         },
      ],
      orderNumber: null,
      rate_total: null,
      status: null,
      user_id: null,
   });

   let [quoteSearch, setQuoteSearch] = useState({
      selectedTerminal: null,
      selectedCustomer: null,
      destination: {
         city: null,
         state: null,
         zip: null,
      },
   });
   // #endregion

   const BootstrapDialog = styled(Dialog)(({ theme }) => ({
      '& .MuiDialogContent-root': {
         padding: theme.spacing(2),
      },
      '& .MuiDialogActions-root': {
         padding: theme.spacing(1),
      },
   }));

   // #region Styles
   const useStyles = makeStyles((theme) => ({
      secondaryHeader: {
         borderBottom: "1px solid #2F3136",
         zIndex: "3",
         width: "100%",
      },
      layoutMain: {
         marginTop: "2rem",
         position: "fixed",
         width: "inherit",
         height: "100%",
      },
      layoutBody: {
         position: "absolute",
         height: "calc(100% - 9.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      layoutBody2: {
         position: "absolute",
         height: "calc(100% - 12.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      search: {
         position: "relative",
         borderRadius: theme.shape.borderRadius,
         border: "1px solid black",
         backgroundColor: "#ffff",
         marginRight: theme.spacing(2),
         marginLeft: 0,
         width: "30%",
         float: "right",
         [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
         },
      },
      searchIcon: {
         color: "black",
         padding: theme.spacing(0, 2),
         height: "100%",
         position: "absolute",
         pointerEvents: "none",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
      save: {
         backgroundColor: "#002D72",
         float: "right",
         color: "white",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "#010440",
         },
      },
      back: {
         color: "white",
         backgroundColor: "#2F3136",
         float: "right",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "black",
         },
      },
      inputRoot: {},
      inputInput: {
         padding: theme.spacing(1, 1, 1, 0),
         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
         transition: theme.transitions.create("width"),
         width: "100%",
         [theme.breakpoints.up("md")]: {
            width: "20ch",
         },
      },
      buttonProgress: {
         color: green[500],
      },
   }));

   const classes = useStyles();

   const StyledTableCell = withStyles((theme) => ({
      head: {
         backgroundColor: "#002D72",
         color: theme.palette.common.white,
      },
   }))(TableCell);

   const viewExistingQuote = () => {
      localStorage.setItem("quoteSearch", JSON.stringify(quoteSearch));
      history.push("/orders")
   }

   const discardQuote = () => {
      setOpen(false);
      setSearchResults(null);
      setQuoteSearch({
         selectedTerminal: null,
         selectedCustomer: null,
         destination: {
            city: null,
            state: null,
            zip: null,
         },
      });
   }
   // #endregion

   // #region useEffect(s)
   //Whenever props changes or when loading changes.
   useEffect(() => {
      if (loading === true) {
         if (props.match.params.orderid) {
            let orderQuery = {
               destination: null,
               order_id: props.match.params.orderid,
               orderNumber: null,
               customer_id: null,
               user_id: null,
               terminal_id: null,
               rate_id: null,
               add_date: null,
               validFrom: null,
               validTill: null,
               startDate: null,
               endDate: null,
               minRate: null,
               maxRate: null,
               status: null,
               lane_type: null,
               minMiles: null,
               maxMiles: null,
               internalExternal: null,
            };

            orderApi
               .getOrders(orderQuery, 0, false)
               .then((resp) => {
                  let selectedOrder = resp.orders[0];
                  let overrideArray = [];

                  if (selectedOrder.items.length === 1) {
                     selectedOrder.items[0].expanded = true;

                     if (!selectedOrder.items[0].overrides) {
                        selectedOrder.items[0].overrides = {
                           base_rate: null,
                           cpm: null,
                           ppr: null,
                           accessorialGuide: [],
                        };
                     } else {
                        overrideArray = [];

                        if (selectedOrder.items[0].overrides.base_rate) {
                           overrideArray.push({
                              field: "base_rate",
                              item: "Rate",
                              original: +calcCPGrate(
                                 selectedOrder.items[0].rate.rateInfo.base_rate,
                                 selectedOrder.items[0].rate.rateInfo.tolls,
                                 selectedOrder.items[0].rate.rateInfo.bobtail,
                              ),
                              override: +selectedOrder.items[0].overrides.base_rate,
                              difference: +Math.abs(
                                 +calcCPGrate(
                                    selectedOrder.items[0].rate.rateInfo.base_rate,
                                    selectedOrder.items[0].rate.rateInfo.tolls,
                                    selectedOrder.items[0].rate.rateInfo.bobtail,
                                 ) - +selectedOrder.items[0].overrides.base_rate,
                              ),
                           });
                        }

                        if (selectedOrder.items[0].overrides.cpm) {
                           overrideArray.push({
                              field: "cpm",
                              item: "CPM",
                              original: +selectedOrder.items[0].fuelTariff.tariffInfo.cpm,
                              override: +selectedOrder.items[0].overrides.cpm,
                              difference: +Math.abs(
                                 +selectedOrder.items[0].fuelTariff.tariffInfo.cpm -
                                 +selectedOrder.items[0].overrides.cpm,
                              ),
                           });
                        }

                        if (selectedOrder.items[0].overrides.ppr) {
                           overrideArray.push({
                              field: "ppr",
                              item: "PPR",
                              original: +selectedOrder.items[0].fuelTariff.tariffInfo.ppr,
                              override: +selectedOrder.items[0].overrides.ppr,
                              difference: +Math.abs(
                                 +selectedOrder.items[0].fuelTariff.tariffInfo.ppr -
                                 +selectedOrder.items[0].overrides.ppr,
                              ),
                           });
                        }

                        //Loop through accessorial guide to calculate.
                        props.accessorialGuides.forEach((guide) => {
                           let overrideExists = _.find(
                              selectedOrder.items[0].overrides.accessorialGuide,
                              (x) => x.name === guide.name,
                           );

                           let defaultValue = _.find(
                              selectedOrder.items[0].accessorialTariff.accessorialGuide,
                              (x) => x.name === guide.name,
                           );

                           if (!_.isEmpty(overrideExists) && !_.isEmpty(defaultValue)) {
                              overrideArray.push({
                                 field: guide.name,
                                 item: guide.name,
                                 original: +defaultValue.value,
                                 override: +overrideExists.value,
                                 difference: +Math.abs(+defaultValue.value - +overrideExists.value),
                              });
                           }
                        });

                        selectedOrder.items[0].overrideDetails = overrideArray;
                     }
                  } else {
                     selectedOrder.items.forEach((item) => {
                        item.expanded = false;

                        if (!item.overrides) {
                           item.overrides = {
                              base_rate: null,
                              cpm: null,
                              ppr: null,
                              accessorialGuide: [],
                           };
                        } else {
                           overrideArray = [];

                           if (item.overrides.base_rate) {
                              overrideArray.push({
                                 field: "base_rate",
                                 item: "Rate",
                                 original: +calcCPGrate(
                                    item.rate.rateInfo.base_rate,
                                    item.rate.rateInfo.tolls,
                                    item.rate.rateInfo.bobtail,
                                 ),
                                 override: +item.overrides.base_rate,
                                 difference: +Math.abs(
                                    +calcCPGrate(
                                       item.rate.rateInfo.base_rate,
                                       item.rate.rateInfo.tolls,
                                       item.rate.rateInfo.bobtail,
                                    ) - +item.overrides.base_rate,
                                 ),
                              });
                           }

                           if (item.overrides.cpm) {
                              overrideArray.push({
                                 field: "cpm",
                                 item: "DPM",
                                 original: +item.fuelTariff.tariffInfo.cpm,
                                 override: +item.overrides.cpm,
                                 difference: +Math.abs(+item.fuelTariff.tariffInfo.cpm - +item.overrides.cpm),
                              });
                           }

                           if (item.overrides.ppr) {
                              overrideArray.push({
                                 field: "ppr",
                                 item: "PPR",
                                 original: +item.fuelTariff.tariffInfo.ppr,
                                 override: +item.overrides.ppr,
                                 difference: +Math.abs(+item.fuelTariff.tariffInfo.ppr - +item.overrides.ppr),
                              });
                           }

                           //Loop through accessorial guide to calculate.
                           props.accessorialGuides.forEach((guide) => {
                              let overrideExists = _.filter(
                                 item.overrides.accessorialGuide,
                                 (x) => x.name === guide.name,
                              );

                              let defaultValue = _.filter(
                                 item.accessorialTariff.accessorialGuide,
                                 (x) => x.name === guide.name,
                              );

                              if (overrideExists.length > 0 && defaultValue.length > 0) {
                                 overrideArray.push({
                                    field: guide.name,
                                    item: guide.name,
                                    original: +defaultValue[0].value,
                                    override: +overrideExists[0].value,
                                    difference: +Math.abs(+defaultValue[0].value - +overrideExists[0].value),
                                 });
                              }
                           });

                           item.overrideDetails = overrideArray;
                        }
                     });
                  }

                  setNewOrder(selectedOrder);

                  //Set quote title.
                  setQuoteTitle("Quote: " + selectedOrder?.orderNumber);

                  //Reset order status, status list, and each object variable.
                  setSelectedOrderStatus(selectedOrder?.status);
               })
               .catch((err) => {
                  openAlertMessage(`Error Loading Quote: ${err}`, "error");
                  setLoading(false);
               });
         } else {
            //Set quote title.
            setQuoteTitle("New Quote");

            //Reset order status, status list, and each object variable.
            setSelectedOrderStatus("IN PROGRESS");

            getStatusList();

            setQuoteSearch({
               selectedTerminal: null,
               selectedCustomer: null,
               destination: {
                  city: null,
                  state: null,
                  zip: null,
               },
            });

            setNewOrder({
               _id: null,
               add_date: null,
               validFrom: null,
               validTill: null,
               items: [
                  {
                     rate: {
                        _id: null,
                        terminal_id: null,
                        customer_id: null,
                        original_customer_id: null,
                        destination: {
                           _id: null,
                           city: null,
                           state: null,
                           zip: null,
                        },
                        rateInfo: {
                           _id: null,
                           miles: null,
                           tolls: null,
                           cost: null,
                           base_rate: null,
                           bobtail: null,
                           lane_type: null,
                           service: null,
                           shipment_type: null,
                           add_date: null,
                           startDate: null,
                           endDate: null,
                        },
                     },
                     customer_id: null,
                     terminal_id: null,
                     fuelTariff: {
                        tariffInfo: {
                           ppr: null,
                           cpm: null,
                        },
                     },
                     accessorialTariff: {
                        _id: null,
                        accessorialGuide: [
                           {
                              _id: null,
                              name: null,
                              pdfVerbiage: null,
                              value: null,
                           },
                        ],
                     },
                     overrides: {
                        base_rate: null,
                        cpm: null,
                        ppr: null,
                        accessorialGuide: [],
                     },
                     _id: null,
                     expanded: null,
                  },
               ],
               orderNumber: null,
               rate_total: null,
               status: null,
               user_id: null,
            });
         }
      }
   }, [loading, props.accessorialGuides]);

   //Reset rate if quote search has changed since rate has been selected. Remove overrides.
   useEffect(() => {
      //Edit nevers searches.
      if (props.match.params.orderid) {
         return;
      }

      setShowDetails(false);

      setNewOrder({
         _id: null,
         add_date: null,
         validFrom: null,
         validTill: null,
         items: [
            {
               rate: {
                  _id: null,
                  terminal_id: null,
                  customer_id: null,
                  original_customer_id: null,
                  destination: {
                     _id: null,
                     city: null,
                     state: null,
                     zip: null,
                  },
                  rateInfo: {
                     _id: null,
                     miles: null,
                     tolls: null,
                     cost: null,
                     base_rate: null,
                     bobtail: null,
                     lane_type: null,
                     service: null,
                     shipment_type: null,
                     add_date: null,
                     startDate: null,
                     endDate: null,
                  },
               },
               customer_id: null,
               terminal_id: null,
               fuelTariff: {
                  tariffInfo: {
                     ppr: null,
                     cpm: null,
                  },
               },
               accessorialTariff: {
                  _id: null,
                  accessorialGuide: [
                     {
                        _id: null,
                        name: null,
                        pdfVerbiage: null,
                        value: null,
                     },
                  ],
               },
               overrides: {
                  base_rate: null,
                  cpm: null,
                  ppr: null,
                  accessorialGuide: [],
               },
               _id: null,
               expanded: null,
            },
         ],
         orderNumber: null,
         rate_total: null,
         status: null,
         user_id: null,
      });

      if (
         (_handleObjectProperty(quoteSearch.selectedTerminal, "_id") ? quoteSearch.selectedTerminal._id : null) !==
         null &&
         (_handleObjectProperty(quoteSearch.selectedCustomer, "_id") ? quoteSearch.selectedCustomer._id : null) !==
         null &&
         (quoteSearch.destination.city !== null ||
            quoteSearch.destination.state !== null ||
            quoteSearch.destination.zip !== null)
      ) {
         checkExistingOrder({
            customer_id: _handleObjectProperty(quoteSearch.selectedCustomer, "_id") ? quoteSearch.selectedCustomer._id : null,
            destination: quoteSearch?.destination,
            terminal_id: _handleObjectProperty(quoteSearch.selectedTerminal, "_id") ? quoteSearch.selectedTerminal._id : null,
            formatForDataGrid: true
         })
      }
   }, [quoteSearch]);

   async function checkExistingOrder(qry) {
      orderApi
         .getOrders(qry, 0, false, 1)
         .then((resp) => {
            if (resp?.orders.length > 0) {
               let addDate = new Date(resp?.orders[0]?.add_date);
               let differenceInDays = Math.floor((new Date() - addDate) / (1000 * 60 * 60 * 24));
               if (differenceInDays < 365) {
                  setOpen(true);
                  return;
               }
            }
            searchForRate();
         })
         .catch((err) => {
            openAlertMessage(`Error Checking Quotes: ${err}`, "error");
         });
   }


   //This runs due to async of redux terminals and customers.
   useEffect(() => {
      if (loading) {
         if (props.match.params.orderid) {
            //Set selected terminal.
            let selectedOrderTerminal = _.filter(props.terminals, (x) => x._id === newOrder.items[0].terminal_id);
            let selectedOrderCustomer = _.filter(props.customers, (x) => x._id === newOrder.items[0].customer_id);
            // Need to check this block of code!
            if (
               selectedOrderTerminal.length > 0 &&
               selectedOrderCustomer.length > 0 &&
               props.accessorialGuides.length > 0
            ) {
               //Set selected destination.
               let selectedDestination = {
                  city: newOrder.items[0].rate.destination.city,
                  state: newOrder.items[0].rate.destination.state,
                  zip: newOrder.items[0].rate.destination.zip,
               };

               //Set quote search.
               setQuoteSearch({
                  selectedTerminal: selectedOrderTerminal[0],
                  selectedCustomer: selectedOrderCustomer[0],
                  destination: selectedDestination,
               });

               //set loading to false.
               setLoading(false);
               setShowDetails(true);
            }
         } else {
            if (
               props.terminals.length > 0 &&
               props.customers.length > 0 &&
               props.accessorialGuides.length > 0 &&
               props.accessorialTariffs.length > 0
            ) {
               setLoading(false);
            }
         }
      }
   }, [props.terminals, props.customers, props.accessorialGuides, props.accessorialTariffs, newOrder]);

   //Calculate each time the rate search changes.
   useEffect(() => {
      if (searchResults) {
         createLineItems();
         setShowDetails(true);
      } else {
         setShowDetails(false);
      }
   }, [searchResults]);

   //Anytime history path changes.
   useEffect(() => {
      if (loading === false) {
         setLoading(true);
      }
   }, [history.location.pathname]);
   // #endregion

   useEffect(() => {
      if (!userDiscountRate) {
         setUserDiscountRate(0);
      }
   }, []);

   //load in security
   useEffect(() => {
      var curUserRoute = props.user.security.routes.filter((rt) => rt.module === "orders");
      var userReadOnly = true;

      if (!props.user.security.admin) {
         switch (curUserRoute[0].permission) {
            case "readWrite":
               userReadOnly = false;
               break;
            case "readOnly":
               userReadOnly = true;
               break;
         }
      } else {
         userReadOnly = false;
      }

      setReadOnly(userReadOnly);
   }, [props.user]);

   // #region inputProp steps.
   //input step decimal amount.
   const inputProps = {
      step: 0.01,
   };

   //input step whole number amount.
   const inputPropsWhole = {
      step: 1.0,
   };
   // #endregion

   // #region Misc. Functions.
   //Open alert message.
   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   //Close alert message
   function closeAlertMessage() {
      setShowAlert(false);
      setAlertMessage(null);
   }

   //Get a list of posible order statuses
   function getStatusList() {
      applicationApi
         .getLookup("ORDERSTATUS")
         .then((resp) => {
            if (resp.lookups !== "undefined") {
               setOrderStatuses(resp.lookups[0].value.statuses[0]);
            }
         })
         .catch((err) => {
            console.error(err);
         });
   }

   //Whenever the customer has been changed
   function onCustomerChange(values) {
      try {
         var newSearch = _.cloneDeep(quoteSearch);
         newSearch.selectedCustomer = values;
         setQuoteSearch(newSearch);
      } catch (err) {
         handleError(err);
      }
   }

   //Whenever the terminal has been changed
   function onTerminalChange(values) {
      try {
         var newSearch = _.cloneDeep(quoteSearch);
         newSearch.selectedTerminal = values;
         setQuoteSearch(newSearch);
      } catch (err) {
         handleError(err);
      }
   }

   //Check to see how many array items were returned in google api results
   function onDestinationChange(event, values) {
      try {
         //need to check to see how many array items were returned in google api results
         var destination = {
            city: null,
            state: null,
            zip: null,
            place_id: null,
         };

         if (values) {
            //input defaults
            destination.city = values.terms[0].value;
            if (
               values.terms[1].value.length > 2 &&
               values.terms[1].value.toUpperCase() !== "USA" &&
               values.terms[1].value.toUpperCase() !== "US"
            ) {
               //See if terms[1] is state or space in city name.
               let state = _.filter(stateList, (x) => x.name.toUpperCase() === values.terms[1].value.toUpperCase());
               let abbreviation = _.filter(
                  stateList,
                  (x) => x.abbreviation.toUpperCase() === values.terms[1].value.toUpperCase(),
               );

               if (state.length > 0 || abbreviation.length > 0) {
                  if (state.length > 0) {
                     destination.state = state[0].abbreviation;

                     if (
                        values.terms[2].value.toUpperCase() !== "USA" &&
                        values.terms[2].value.toUpperCase() !== "US"
                     ) {
                        destination.zip = values.terms[2].value;
                     } else if (values.terms.length >= 4) {
                        if (
                           values.terms[3].value.toUpperCase() !== "USA" &&
                           values.terms[3].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[3].value;
                        }
                     }
                  } else {
                     destination.state = abbreviation[0].abbreviation;
                     if (
                        values.terms[2].value.toUpperCase() !== "USA" &&
                        values.terms[2].value.toUpperCase() !== "US"
                     ) {
                        destination.zip = values.terms[2].value;
                     } else if (values.terms.length >= 4) {
                        if (
                           values.terms[3].value.toUpperCase() !== "USA" &&
                           values.terms[3].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[3].value;
                        }
                     }
                  }
               } else {
                  destination.city += " " + values.terms[1].value;
                  if (values.terms[2].value.length > 2) {
                     destination.city += " " + values.terms[2].value;

                     destination.state = values.terms[3].value;

                     if (values.terms.length >= 5) {
                        if (
                           values.terms[4].value.toUpperCase() !== "USA" &&
                           values.terms[4].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[4].value;
                        }
                     }
                  } else {
                     destination.state = values.terms[2].value;

                     if (values.terms.length >= 5) {
                        destination.zip = values.terms[3].value;
                     }
                  }
               }
            } else {
               if (values.terms[1].value.toUpperCase() !== "USA" && values.terms[1].value.toUpperCase() !== "US") {
                  destination.state = values.terms[1].value;
               }

               //if searched by zip, then it will appear with 4 items in array, last one always being country
               if (values.terms.length >= 4) {
                  destination.zip = values.terms[2].value;
               }
            }
            destination.place_id = values.place_id;
         }

         var newSearch = _.cloneDeep(quoteSearch);
         newSearch.destination = destination;
         setQuoteSearch(newSearch);
      } catch (err) {
         handleError(err);
      }
   }

   function finalOverrideDetails(overrideDetails) {
      let finalArr = _.filter(overrideDetails, (x) => x?.difference != 0);
      return finalArr;
   }

   //Create line items based on rate search.
   function createLineItems() {
      try {
         //Create temp object to manipulate.
         let newOrderChange = { ...newOrder };

         //Merge from searchResults.
         _.merge(newOrderChange.items, searchResults);

         for (var object in newOrderChange.items) {
            //Assign customer id; remove id.
            newOrderChange.items[object].original_customer_id = newOrderChange.items[object].customer_id;
            newOrderChange.items[object].customer_id = quoteSearch.selectedCustomer._id;
            //Create the rate.
            newOrderChange.items[object].rate = {
               customer_id: newOrderChange.items[object].customer_id,
               original_customer_id: newOrderChange.items[object].original_customer_id,
               delete_date: null,
               _id: newOrderChange.items[object]._id,
               terminal_id: newOrderChange.items[object].terminal_id,
               destination: newOrderChange.items[object].destination,
               rateInfo: _.cloneDeep(newOrderChange.items[object].rateInfo),
               active: newOrderChange.items[object].active,
               add_date: newOrderChange.items[object].add_date,
            };
            //Manually setup overrides.
            newOrderChange.items[object].overrides = {
               base_rate: null,
               cpm: null,
               ppr: null,
               accessorialGuide: [],
            };
            //Delete unnecessary fields to match schema.
            if (newOrderChange.items[object].hasOwnProperty("accessorialTariff_id")) {
               delete newOrderChange.items[object].accessorialTariff_id;
            }

            if (newOrderChange.items[object].hasOwnProperty("active")) {
               delete newOrderChange.items[object].active;
            }

            if (newOrderChange.items[object].hasOwnProperty("add_date")) {
               delete newOrderChange.items[object].add_date;
            }

            if (newOrderChange.items[object].hasOwnProperty("delete_date")) {
               delete newOrderChange.items[object].delete_date;
            }

            if (newOrderChange.items[object].hasOwnProperty("destination")) {
               delete newOrderChange.items[object].destination;
            }

            if (newOrderChange.items[object].hasOwnProperty("fuelTariff_id")) {
               delete newOrderChange.items[object].fuelTariff_id;
            }

            if (newOrderChange.items[object].hasOwnProperty("liquor")) {
               delete newOrderChange.items[object].liquor;
            }

            if (newOrderChange.items[object].hasOwnProperty("rateInfo")) {
               delete newOrderChange.items[object].rateInfo;
            }

            if (newOrderChange.items[object].hasOwnProperty("historicalRateInfo")) {
               delete newOrderChange.items[object].historicalRateInfo;
            }

            if (newOrderChange.items[object].hasOwnProperty("selectedRateInfo")) {
               delete newOrderChange.items[object].selectedRateInfo;
            }

            //Delete fields in fuel tariff to match schema.
            if (newOrderChange.items[object].fuelTariff) {
               if (newOrderChange.items[object].fuelTariff.hasOwnProperty("active")) {
                  delete newOrderChange.items[object].fuelTariff.active;
               }

               if (newOrderChange.items[object].fuelTariff.hasOwnProperty("add_date")) {
                  delete newOrderChange.items[object].fuelTariff.add_date;
               }

               if (newOrderChange.items[object].fuelTariff.hasOwnProperty("customer_id")) {
                  delete newOrderChange.items[object].fuelTariff.customer_id;
               }

               if (newOrderChange.items[object].fuelTariff.hasOwnProperty("delete_date")) {
                  delete newOrderChange.items[object].fuelTariff.delete_date;
               }

               if (newOrderChange.items[object].fuelTariff.hasOwnProperty("name")) {
                  delete newOrderChange.items[object].fuelTariff.name;
               }

               if (
                  newOrderChange.items[object].fuelTariff.hasOwnProperty("overUnder") &&
                  newOrderChange.items[object].fuelTariff.overUnder
               ) {
                  newOrderChange.items[object].fuelTariff.tariffInfo.ppr = newOrderChange.items[object].fuelTariff
                     .overUnder.ppr
                     ? newOrderChange.items[object].fuelTariff.overUnder.ppr
                     : newOrderChange.items[object].fuelTariff.tariffInfo.ppr;
                  newOrderChange.items[object].fuelTariff.tariffInfo.cpm = newOrderChange.items[object].fuelTariff
                     .overUnder.cpm
                     ? newOrderChange.items[object].fuelTariff.overUnder.cpm
                     : newOrderChange.items[object].fuelTariff.tariffInfo.cpm;
                  delete newOrderChange.items[object].fuelTariff.overUnder;
               }

               if (newOrderChange.items[object].fuelTariff.hasOwnProperty("terminal_ids")) {
                  delete newOrderChange.items[object].fuelTariff.terminal_ids;
               }

               if (newOrderChange.items[object].fuelTariff.hasOwnProperty("__v")) {
                  delete newOrderChange.items[object].fuelTariff.__v;
               }

               if (newOrderChange.items[object].fuelTariff.hasOwnProperty("_id")) {
                  delete newOrderChange.items[object].fuelTariff._id;
               }
            }

            //Delete fields in fuel tariff tariffInfo to match schema.
            if (newOrderChange.items[object].fuelTariff.tariffInfo) {
               if (newOrderChange.items[object].fuelTariff.tariffInfo.hasOwnProperty("add_date")) {
                  delete newOrderChange.items[object].fuelTariff.tariffInfo.add_date;
               }

               if (newOrderChange.items[object].fuelTariff.tariffInfo.hasOwnProperty("endDate")) {
                  delete newOrderChange.items[object].fuelTariff.tariffInfo.endDate;
               }

               if (newOrderChange.items[object].fuelTariff.tariffInfo.hasOwnProperty("_id")) {
                  delete newOrderChange.items[object].fuelTariff.tariffInfo._id;
               }
            }

            //Delete fields in accessorial tariff to match schema.
            if (newOrderChange.items[object].accessorialTariff) {
               if (newOrderChange.items[object].accessorialTariff.hasOwnProperty("active")) {
                  delete newOrderChange.items[object].accessorialTariff.active;
               }

               if (newOrderChange.items[object].accessorialTariff.hasOwnProperty("add_date")) {
                  delete newOrderChange.items[object].accessorialTariff.add_date;
               }

               if (newOrderChange.items[object].accessorialTariff.hasOwnProperty("customer_id")) {
                  delete newOrderChange.items[object].accessorialTariff.customer_id;
               }

               if (newOrderChange.items[object].accessorialTariff.hasOwnProperty("delete_date")) {
                  delete newOrderChange.items[object].accessorialTariff.delete_date;
               }

               if (newOrderChange.items[object].accessorialTariff.hasOwnProperty("name")) {
                  delete newOrderChange.items[object].accessorialTariff.name;
               }

               if (newOrderChange.items[object].accessorialTariff.hasOwnProperty("terminal_ids")) {
                  delete newOrderChange.items[object].accessorialTariff.terminal_ids;
               }

               if (newOrderChange.items[object].accessorialTariff.hasOwnProperty("__v")) {
                  delete newOrderChange.items[object].accessorialTariff.__v;
               }

               _.forEach(props.accessorialGuides, (x) => {
                  const guideFound = _.filter(
                     newOrderChange.items[object].accessorialTariff.accessorialGuide,
                     (y) => y._id === x._id,
                  );

                  //this line create extra fields which are removed later
                  if (guideFound.length <= 0) {
                     newOrderChange.items[object].accessorialTariff.accessorialGuide.push({
                        _id: x._id,
                        name: x.name,
                        pdfVerbiage: x.pdfVerbiage,
                        value: 0,
                     });
                  }
               });

               //get all guides that shouldn't be on tariff.
               newOrderChange.items[object].accessorialTariff.accessorialGuide.forEach((guide, index) => {
                  const guideRemoved = _.find(props.accessorialGuides, (x) => (x._id === guide._id || x.name.toLowerCase() === guide.name.toLowerCase()));

                  if (!guideRemoved) {
                     newOrderChange.items[object].accessorialTariff.accessorialGuide.splice(index, 1);
                  } else {
                     newOrderChange.items[object].accessorialTariff.accessorialGuide[index].name = guideRemoved.name;
                  }
               });

               newOrderChange.items[object].accessorialTariff.accessorialGuide = _.filter(
                  newOrderChange.items[object].accessorialTariff.accessorialGuide,
                  (x) => x.value !== null,
               );

               newOrderChange.items[object].accessorialTariff.accessorialGuide = _.orderBy(
                  newOrderChange.items[object].accessorialTariff.accessorialGuide,
                  ["name"],
                  ["asc"],
               );

               //loop and remove repited values (default setted to 0)
               for (let i = 1; i < newOrderChange.items[object].accessorialTariff.accessorialGuide.length; i++) {
                  if (
                     newOrderChange.items[object].accessorialTariff.accessorialGuide[i]?.name ===
                     newOrderChange.items[object].accessorialTariff.accessorialGuide[i - 1]?.name
                  ) {
                     newOrderChange.items[object].accessorialTariff.accessorialGuide.splice(i, 1);
                  }
               }
            }

            if (newOrderChange.items.length > 1) {
               newOrderChange.items[object].expanded = false;
            } else {
               newOrderChange.items[object].expanded = true;
            }
         }
         setNewOrder(newOrderChange);
      } catch (err) {
         handleError(err);
      }
   }

   //Search for valid rate(s)
   function searchForRate() {
      setOpen(false);
      if (
         (_handleObjectProperty(quoteSearch.selectedTerminal, "_id") ? quoteSearch.selectedTerminal._id : null) === null
      ) {
         openAlertMessage("Please select a terminal to search.", "warning");
         return;
      }

      if (
         (_handleObjectProperty(quoteSearch.selectedCustomer, "_id") ? quoteSearch.selectedCustomer._id : null) === null
      ) {
         openAlertMessage("Please select a customer to search.", "warning");
         return;
      }

      if (
         quoteSearch.destination.city === null &&
         quoteSearch.destination.state === null &&
         quoteSearch.destination.zip === null
      ) {
         openAlertMessage("Please select a destination to search.", "warning");
         return;
      }

      //loop through search queries to create properly formatted searchquery object for the api
      try {
         var newMultiSearchResults = new rateApi.SearchQuery(
            quoteSearch.selectedCustomer._id,
            quoteSearch.selectedTerminal._id,
            quoteSearch.destination,
            true,
         );

         newMultiSearchResults = [newMultiSearchResults];
      } catch (err) {
         handleError(err);
      }

      rateApi
         .searchRates(newMultiSearchResults)
         .then((resp) => {
            //First assign the fuel tariff up front.
            return getFuelTariffs(
               resp,
               props.fuelTariffs,
               props.customers,
               quoteSearch.selectedCustomer,
               props.terminals,
            );
         })
         .then((resp) => {
            //First assign the accessorial tariff up front.
            return getAccessorialTariffs(
               resp,
               props.accessorialTariffs,
               props.customers,
               quoteSearch.selectedCustomer,
               props.terminals,
            );
         })
         .then((resp) => {
            if (resp.rates.length === 0) {
               //Rate not found.
               openAlertMessage(
                  "No search rates from: " +
                  quoteSearch.selectedTerminal.name +
                  " to: " +
                  quoteSearch.destination.city +
                  "," +
                  quoteSearch.destination.state +
                  ".",
                  "warning",
               );
            } else {
               //Next set full search results.
               setSearchResults(resp.rates);
            }

            return;
         })
         .catch((err) => {
            openAlertMessage(`Search Failed: ${err}`, "error");
            return;
         });
   }

   //Whenever an override is changed on the form.
   function onOverrideChange(newOverrideChange, index, overrideValue, event) {
      try {
         if (+event.target.value < 0) {
            event.target.value = 0;
         } else {
            if (!arrOfField.includes(overrideValue)) {
               if (event.target.value > 999.99) {
                  return
               }
               if (event.target.value % 1) {
                  if (`${event.target.value}`.split(".")[1].length >= 3) {
                     return
                  }
               }
            }

            if (!event.target.value) {
               event.target.value = 0;
            }
         }

         //Check to see if tariff matches. If it does, null out the override.
         if (overrideValue === "cpm" || overrideValue === "ppr") {
            if (event.target.value == newOrder.items[index].fuelTariff.tariffInfo[overrideValue]) {
               event.target.value = null;
            }
         } else if (overrideValue === "base_rate") {
            if (event.target.value) {
               if (
                  event.target.value ==
                  calcCPGrate(
                     newOrder.items[index].rate.rateInfo[overrideValue],
                     newOrder.items[index].rate.rateInfo.tolls,
                     newOrder.items[index].rate.rateInfo.bobtail,
                  )
               ) {
                  event.target.value = null;
               }
            } else {
               event.target.value = null;
            }
         } else {
            const accessorialGuide = _.find(
               newOrder.items[index].accessorialTariff.accessorialGuide,
               (x) => x.name === overrideValue,
            );

            // Not required anymore -> CPGA-196
            // if (!_.isEmpty(accessorialGuide)) {
            //    if (event.target.value == accessorialGuide.value) {
            //       event.target.value = null;
            //    }
            // }
         }

         let newOverride = { ...newOverrideChange };

         if (overrideValue === "cpm" || overrideValue === "ppr" || overrideValue === "base_rate") {
            newOverride[overrideValue] = event.target.value ? +event.target.value : null;
         } else {
            const accessorialGuide = _.find(
               newOrder.items[index].accessorialTariff.accessorialGuide,
               (x) => x.name === overrideValue,
            );

            newOverride.accessorialGuide = _.filter(newOverride.accessorialGuide, (x) => x.name !== overrideValue);

            newOverride.accessorialGuide.push({
               _id: accessorialGuide._id,
               name: accessorialGuide.name,
               pdfVerbiage: accessorialGuide.pdfVerbiage,
               value: event.target.value ? +event.target.value : null,
            });
         }

         let newOrderChange = { ...newOrder };
         newOrderChange.items[index].overrides = newOverride;
         let overrideArray = [];
         let indexOverrideDetail = -1;

         if (event.target.value || event.target.value === 0) {
            if (newOrderChange.items[index].overrideDetails) {
               overrideArray = newOrderChange.items[index].overrideDetails;

               indexOverrideDetail = _.findIndex(overrideArray, { field: overrideValue });

               if (indexOverrideDetail >= 0) {
                  delete newOrderChange.items[index].overrideDetails[indexOverrideDetail];
               }
            }

            switch (overrideValue) {
               case "base_rate":
                  //rate values
                  if (indexOverrideDetail >= 0) {
                     overrideArray[indexOverrideDetail] = {
                        field: overrideValue,
                        item: "Rate",
                        original: +calcCPGrate(
                           newOrderChange.items[index].rate.rateInfo[overrideValue],
                           newOrderChange.items[index].rate.rateInfo.tolls,
                           newOrderChange.items[index].rate.rateInfo.bobtail,
                        ),
                        override: +newOrderChange.items[index].overrides[overrideValue],
                        difference: +Math.abs(
                           +calcCPGrate(
                              newOrderChange.items[index].rate.rateInfo[overrideValue],
                              newOrderChange.items[index].rate.rateInfo.tolls,
                              newOrderChange.items[index].rate.rateInfo.bobtail,
                           ) - +newOrderChange.items[index].overrides[overrideValue],
                        ),
                     };
                  } else {
                     overrideArray.push({
                        field: overrideValue,
                        item: "Rate",
                        original: +calcCPGrate(
                           newOrderChange.items[index].rate.rateInfo[overrideValue],
                           newOrderChange.items[index].rate.rateInfo.tolls,
                           newOrderChange.items[index].rate.rateInfo.bobtail,
                        ),
                        override: +newOrderChange.items[index].overrides[overrideValue],
                        difference: +Math.abs(
                           +calcCPGrate(
                              newOrderChange.items[index].rate.rateInfo[overrideValue],
                              newOrderChange.items[index].rate.rateInfo.tolls,
                              newOrderChange.items[index].rate.rateInfo.bobtail,
                           ) - +newOrderChange.items[index].overrides[overrideValue],
                        ),
                     });
                  }
                  break;
               case "cpm":
                  //fuel values
                  if (indexOverrideDetail >= 0) {
                     overrideArray[indexOverrideDetail] = {
                        field: overrideValue,
                        item: "DPM",
                        original: +newOrderChange.items[index].fuelTariff.tariffInfo[overrideValue],
                        override: +newOrderChange.items[index].overrides[overrideValue],
                        difference: +Math.abs(
                           +newOrderChange.items[index].fuelTariff.tariffInfo[overrideValue] -
                           +newOrderChange.items[index].overrides[overrideValue],
                        ),
                     };
                  } else {
                     overrideArray.push({
                        field: overrideValue,
                        item: "DPM",
                        original: +newOrderChange.items[index].fuelTariff.tariffInfo[overrideValue],
                        override: +newOrderChange.items[index].overrides[overrideValue],
                        difference: +Math.abs(
                           +newOrderChange.items[index].fuelTariff.tariffInfo[overrideValue] -
                           +newOrderChange.items[index].overrides[overrideValue],
                        ),
                     });
                  }
                  break;
               case "ppr":
                  //fuel values
                  if (indexOverrideDetail >= 0) {
                     overrideArray[indexOverrideDetail] = {
                        field: overrideValue,
                        item: "PPR",
                        original: +newOrderChange.items[index].fuelTariff.tariffInfo[overrideValue],
                        override: +newOrderChange.items[index].overrides[overrideValue],
                        difference: +Math.abs(
                           +newOrderChange.items[index].fuelTariff.tariffInfo[overrideValue] -
                           +newOrderChange.items[index].overrides[overrideValue],
                        ),
                     };
                  } else {
                     overrideArray.push({
                        field: overrideValue,
                        item: "PPR",
                        original: +newOrderChange.items[index].fuelTariff.tariffInfo[overrideValue],
                        override: +newOrderChange.items[index].overrides[overrideValue],
                        difference: +Math.abs(
                           +newOrderChange.items[index].fuelTariff.tariffInfo[overrideValue] -
                           +newOrderChange.items[index].overrides[overrideValue],
                        ),
                     });
                  }
                  break;
               default:
                  let overrideExists = _.find(
                     newOrderChange.items[index].overrides.accessorialGuide,
                     (x) => x.name === overrideValue,
                  );

                  let defaultValue = _.find(
                     newOrderChange.items[index].accessorialTariff.accessorialGuide,
                     (x) => x.name === overrideValue,
                  );

                  if (!_.isEmpty(overrideExists) && !_.isEmpty(defaultValue)) {
                     if (indexOverrideDetail >= 0) {
                        overrideArray[indexOverrideDetail] = {
                           field: defaultValue.name,
                           item: defaultValue.name,
                           original: +defaultValue.value,
                           override: +overrideExists.value,
                           difference: +Math.abs(+overrideExists.value - +defaultValue.value),
                        };
                     } else {
                        overrideArray.push({
                           field: defaultValue.name,
                           item: defaultValue.name,
                           original: +defaultValue.value,
                           override: +overrideExists.value,
                           difference: +Math.abs(+overrideExists.value - +defaultValue.value),
                        });
                     }
                  }
                  break;
            }

            newOrderChange.items[index].overrideDetails = overrideArray;
         } else {
            if (newOrderChange.items[index].overrideDetails) {
               overrideArray = newOrderChange.items[index].overrideDetails;

               indexOverrideDetail = _.findIndex(overrideArray, { field: overrideValue });

               if (indexOverrideDetail >= 0) {
                  delete newOrderChange.items[index].overrideDetails[indexOverrideDetail];
               }
            }
         }

         if (newOrderChange.items[index].overrideDetails) {
            if (newOrderChange.items[index].overrideDetails.length <= 0) {
               newOrderChange.items[index].overrideDetails = null;
            } else {
               newOrderChange.items[index].overrideDetails = newOrderChange.items[index].overrideDetails.filter(
                  (value) => Object.keys(value).length !== 0,
               );
            }
         } else {
            newOrderChange.items[index].overrideDetails = null;
         }

         setNewOrder(newOrderChange);
      } catch (err) {
         handleError(err);
      }
   }

   function resetFuel(index) {
      try {
         let newOrderChange = { ...newOrder };
         newOrderChange.items[index].overrides.base_rate = null;
         newOrderChange.items[index].overrides.cpm = null;
         newOrderChange.items[index].overrides.ppr = null;

         if (newOrderChange.items[index].overrideDetails) {
            for (let object in newOrderChange.items[index].overrideDetails) {
               if (
                  newOrderChange.items[index].overrideDetails[object].field === "base_rate" ||
                  newOrderChange.items[index].overrideDetails[object].field === "cpm" ||
                  newOrderChange.items[index].overrideDetails[object].field === "ppr"
               ) {
                  delete newOrderChange.items[index].overrideDetails[object];
               }
            }

            newOrderChange.items[index].overrideDetails = newOrderChange.items[index].overrideDetails.filter(
               (value) => Object.keys(value).length !== 0,
            );

            if (newOrderChange.items[index].overrideDetails.length <= 0) {
               newOrderChange.items[index].overrideDetails = null;
            }
         }

         setNewOrder(newOrderChange);
      } catch (err) {
         handleError(err);
      }
   }

   //Whenever accordion expanded is changed on the form.
   function onExpandedChange(index, event) {
      try {
         let newOrderChange = { ...newOrder };

         newOrderChange.items[index].expanded = !newOrderChange.items[index].expanded;

         setNewOrder(newOrderChange);
      } catch (err) {
         handleError(err);
      }
   }

   //Reset accessorial to default.
   function resetAccessorial(index) {
      try {
         let newOrderChange = { ...newOrder };
         newOrderChange.items[index].overrides.accessorialGuide = [];
         if (newOrderChange.items[index].overrideDetails) {
            for (let object in newOrderChange.items[index].overrideDetails) {
               if (
                  newOrderChange.items[index].overrideDetails[object].field !== "base_rate" &&
                  newOrderChange.items[index].overrideDetails[object].field !== "cpm" &&
                  newOrderChange.items[index].overrideDetails[object].field !== "ppr"
               ) {
                  delete newOrderChange.items[index].overrideDetails[object];
               }
            }

            if (newOrderChange.items[index].overrideDetails.length <= 0) {
               newOrderChange.items[index].overrideDetails = null;
            }
         }

         setNewOrder(newOrderChange);
      } catch (err) {
         handleError(err);
      }
   }

   //I CAN'T GET THIS TO WORK AND I THINK THEY WILL HAVE ISSUES WITH THE TAB. CHECK IN FUTURE!!!!!
   function onKeyUp(event) {
      if (event.key === "Tab") {
         //event.preventDefault();
      }
   }

   //Breadcrumb function.
   function breadcrumbOnClick(path) {
      if (path) {
         history.push(path);
      }
   }
   // #endregion

   // #region Calculation Functions
   //Calculate CPG rates
   const calcCPGrate = (base_rate, tolls, bobtail) => {
      try {
         return +(base_rate ? base_rate : 0) + +(tolls ? tolls : 0) + +(bobtail ? bobtail : 0);
      } catch (err) {
         handleError(err);
      }
   };

   //Remove CPG rates
   const removeCPGrate = (base_rate, tolls, bobtail) => {
      try {
         return +(base_rate ? base_rate : 0) - +(tolls ? tolls : 0) - +(bobtail ? bobtail : 0);
      } catch (err) {
         handleError(err);
      }
   };

   function getRateAmount(newOrder) {
      try {
         let totalRate = 0;

         for (let item in newOrder.items) {
            totalRate += +parseFloat(
               newOrder.items[item].overrides.base_rate
                  ? removeCPGrate(
                     newOrder.items[item].overrides.base_rate,
                     newOrder.items[item].rate.rateInfo.tolls,
                     newOrder.items[item].rate.rateInfo.bobtail,
                  )
                  : newOrder.items[item].overrides.base_rate === 0
                     ? removeCPGrate(
                        newOrder.items[item].overrides.base_rate,
                        newOrder.items[item].rate.rateInfo.tolls,
                        newOrder.items[item].rate.rateInfo.bobtail,
                     )
                     : newOrder.items[item].rate.rateInfo
                        ? newOrder.items[item].rate.rateInfo.base_rate
                           ? newOrder.items[item].rate.rateInfo.base_rate
                           : 0
                        : 0,
            );
         }

         return totalRate;
      } catch (err) {
         handleError(err);
      }
   }
   // #endregion

   // #region Save Function
   //Save order.
   async function saveOrder(type) {
      if (!_handleObjectProperty(quoteSearch.selectedCustomer, "_id")) {
         openAlertMessage("You must select a customer!", "warning");
         return;
      }

      if (!_handleObjectProperty(quoteSearch.selectedTerminal, "_id")) {
         openAlertMessage("You must select a terminal!", "warning");
         return;
      }

      if (!quoteSearch.destination) {
         openAlertMessage("You must select a location!", "warning");
         return;
      }

      if (!newOrder.items) {
         openAlertMessage("You must select a rate! Please try searching again.", "warning");
         return;
      }

      let rateTotal = getRateAmount(newOrder);
      //Match the order schema.
      var saveNewOrder = {
         _id: newOrder._id ? newOrder._id : null,
         add_date: newOrder.add_date && newOrder.add_date,
         user_id: props.user._id,
         items: newOrder.items,
         rate_total: +rateTotal,
         orderNumber: newOrder.orderNumber ? newOrder.orderNumber : null,
         status: type === "DOWNLOAD" ? "COMPLETED" : selectedOrderStatus ? selectedOrderStatus : "IN PROGRESS",
      };
      if (props.user.security.admin) {
         let pair = {
            validFrom: getValidityDate('validFrom', validFrom),
            validTill: getValidityDate('validTill', validTill),
         }
         saveNewOrder = { ...saveNewOrder, ...pair };
      }

      for (var item in saveNewOrder.items) {
         if (saveNewOrder.items[item].overrides.base_rate || saveNewOrder.items[item].overrides.base_rate === 0) {
            if (!props.user.security.admin) {
               if (
                  saveNewOrder.items[item].overrides.base_rate <
                  saveNewOrder.items[item].rate.rateInfo.base_rate -
                  saveNewOrder.items[item].rate.rateInfo.base_rate * (userDiscountRate / 100)
               ) {
                  openAlertMessage("Override base rate exceeds user discount limit!", "warning");
                  saveNewOrder = null;
                  return;
               }
            }
         }

         if (saveNewOrder.items[item].overrideDetails) {
            delete saveNewOrder.items[item].overrideDetails;
            delete saveNewOrder.items[item].expanded;
         }

         if (saveNewOrder.items[item].overrides) {
            if (saveNewOrder.items[item].overrides.accessorialGuide) {
               if (saveNewOrder.items[item].overrides.accessorialGuide.length <= 0) {
                  delete saveNewOrder.items[item].overrides.accessorialGuide;
               }
            }

            for (var override in saveNewOrder.items[item].overrides) {
               if (
                  saveNewOrder.items[item].overrides[override] === null ||
                  saveNewOrder.items[item].overrides[override] === ""
               ) {
                  delete saveNewOrder.items[item].overrides[override];
               }
            }
         }

         delete saveNewOrder.items[item].expanded;
         delete saveNewOrder.items[item].original_customer_id;
      }

      if (type === "EMAIL") {
         setButtonClicked(true);
         setSaveAndSendLoading(true);
      } else if (type === "DOWNLOAD") {
         setButtonClicked(true);
         setSaveAndDownloadLoading(true);
      } else {
         setButtonClicked(true);
         setSaveLoading(true);
      }

      //Don't save if already completed.
      if (selectedOrderStatus === "COMPLETED") {
         if (type === "EMAIL") {
            setNewOrderResponse(saveNewOrder);
            setButtonClicked(false);
            setSaveAndSendLoading(false);
            setOrdersNewEmailModal(true);
            return;
         } else if (type === "DOWNLOAD") {
            //get pdf and open new window to view it
            orderApi
               .getRateSheetPortal(saveNewOrder._id, null)
               .then((response) => {
                  if (response && response.ok === true) {
                     return response.arrayBuffer().then((buffer) => {
                        const blob = new Blob([buffer], { type: "application/pdf" });
                        saveAs(blob, `${saveNewOrder.orderNumber}.pdf`);
                        setButtonClicked(false);
                        setSaveAndDownloadLoading(false);
                     });
                  } else {
                     setAlertType("error");
                     setAlertMessage("Error loading PDF rate sheet. Please contact your administrator for assistance.");
                     setButtonClicked(false);
                     setSaveAndDownloadLoading(false);
                  }
               })
               .catch((err) => {
                  setAlertType("error");
                  setAlertMessage(`Error Loading PDF Rate Sheet: ${err}.`);
                  setButtonClicked(false);
                  setSaveAndDownloadLoading(false);
               });

            return;
         }
      }

      //Call checkout API.
      orderApi.checkout(saveNewOrder, accessorialComment).then((resp) => {
         openAlertMessage(`Successfully saved quote: ${resp.order.orderNumber}`, "success");

         //Add to log activity.
         try {
            if (type === "EMAIL") {
               setNewOrderResponse(resp.order);
               setButtonClicked(false);
               setSaveAndSendLoading(false);
               setOrdersNewEmailModal(true);
            } else if (type === "DOWNLOAD") {
               orderApi
                  .getRateSheetPortal(resp.order._id, null)
                  .then((response) => {
                     if (response && response.ok === true) {
                        return response.arrayBuffer().then((buffer) => {
                           const blob = new Blob([buffer], { type: "application/pdf" });
                           saveAs(blob, `${resp.order.orderNumber}.pdf`);
                           setButtonClicked(false);
                           setSaveAndDownloadLoading(false);
                        });
                     } else {
                        setAlertType("error");
                        setAlertMessage(
                           "Error loading PDF rate sheet. Please contact your administrator for assistance.",
                        );
                        setButtonClicked(false);
                        setSaveAndDownloadLoading(false);
                     }
                  })
                  .catch((err) => {
                     setAlertType("error");
                     setAlertMessage(`Error Loading PDF Rate Sheet: ${err}.`);
                     setButtonClicked(false);
                     setSaveAndDownloadLoading(false);
                  });

               setButtonClicked(false);
               setSaveAndDownloadLoading(false);
            } else {
               setButtonClicked(false);
               setSaveLoading(false);
            }
         } catch (err) {
            openAlertMessage("Unable to save quote.", "failure");

            if (type === "EMAIL") {
               setButtonClicked(false);
               setSaveAndSendLoading(false);
            } else if (type === "DOWNLOAD") {
               setButtonClicked(false);
               setSaveAndDownloadLoading(false);
            } else {
               setButtonClicked(false);
               setSaveLoading(false);
            }
            console.error(err);
            return;
         }
         setLoading(true);
      });
   }

   //Preview order.
   function previewOrder() {
      if (!_handleObjectProperty(quoteSearch.selectedCustomer, "_id")) {
         openAlertMessage("You must select a customer!", "warning");
         return;
      }

      if (!_handleObjectProperty(quoteSearch.selectedTerminal, "_id")) {
         openAlertMessage("You must select a terminal!", "warning");
         return;
      }

      if (!quoteSearch.destination) {
         openAlertMessage("You must select a location!", "warning");
         return;
      }

      if (!newOrder.items) {
         openAlertMessage("You must select a rate! Please try searching again.", "warning");
         return;
      }

      let rateTotal = getRateAmount(newOrder);

      //Match the order schema.
      var saveNewOrder = {
         _id: newOrder._id ? newOrder._id : null,
         add_date: newOrder.add_date && newOrder.add_date,
         user_id: props.user._id,
         items: newOrder.items,
         rate_total: +rateTotal,
         orderNumber: newOrder.orderNumber ? newOrder.orderNumber : "PREVIEW",
         status: newOrder.status ? newOrder.status : "IN PROGRESS",
      };

      if (props.user.security.admin) {
         let pair = {
            validFrom: getValidityDate('validFrom', validFrom),
            validTill: getValidityDate('validTill', validTill),
         }
         saveNewOrder = { ...saveNewOrder, ...pair };
      }
      for (var item in saveNewOrder.items) {
         if (saveNewOrder.items[item].overrides.base_rate || saveNewOrder.items[item].overrides.base_rate === 0) {
            if (!props.user.security.admin) {
               if (
                  saveNewOrder.items[item].overrides.base_rate <
                  saveNewOrder.items[item].rate.rateInfo.base_rate -
                  saveNewOrder.items[item].rate.rateInfo.base_rate * (userDiscountRate / 100)
               ) {
                  openAlertMessage("Override base rate exceeds user discount limit!", "warning");
                  saveNewOrder = null;
                  return;
               }
            }
         }
      }

      setButtonClicked(true);
      setSavePreviewLoading(true);

      orderApi
         .getRateSheetPortal("PREVIEW", saveNewOrder)
         .then((response) => {
            if (response && response.ok === true) {
               return response.arrayBuffer().then((buffer) => {
                  const blob = new Blob([buffer], { type: "application/pdf" });
                  window.open(URL.createObjectURL(blob));
                  setButtonClicked(false);
                  setSavePreviewLoading(false);
               });
            } else {
               openAlertMessage(
                  "Error loading PDF rate sheet. Please contact your administrator for assistance.",
                  "error",
               );
               setButtonClicked(false);
               setSavePreviewLoading(false);
            }
         })
         .catch((err) => {
            setAlertType("error");
            setAlertMessage(`Error Loading PDF Rate Sheet: ${err}.`);
            setButtonClicked(false);
            setSavePreviewLoading(false);
         });
   }
   // #endregion

   //Add tariff name
   function getTariffLabel(item) {
      if (props.customers.find((cust) => cust._id === item.rate.original_customer_id)) {
         return props.customers.find((cust) => cust._id === item.rate.original_customer_id).name;
      } else {
         return "Default";
      }
   }

   const handleChange = (e, field) => {
      let date = new Date(e);
      let newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toLocaleDateString();
      if (new Date(getValidityDate('validFrom')) < newDate) {
         return;
      }
      else {
         newOrder[field] = newDate;
         if (field === 'validFrom') {
            setValidFrom(newOrder[field]);
            setValidTill(new Date(new Date(newOrder[field]).setDate(new Date(newOrder[field]).getDate() + 60)).toLocaleDateString())
         }
         if (field === 'validTill') {
            setValidTill(newOrder[field])
         }
      }
   }

   const getValidityDate = (field, value) => {
      if (field === 'validFrom') {
         if (value) {
            return value;
         }
         else if (newOrder.validFrom) {
            return new Date(newOrder.validFrom).toLocaleDateString();
         }
         else if (newOrder.add_date) {
            return new Date(newOrder.add_date).toLocaleDateString();
         }
         else {
            return new Date().toLocaleDateString();
         }
      }
      if (field === 'validTill') {
         if (value) {
            return value;
         }
         else if (newOrder.validTill) {
            return new Date(newOrder.validTill).toLocaleDateString();
         }
         else if (newOrder.add_date) {
            return new Date(new Date(newOrder?.add_date).setDate(new Date(newOrder?.add_date).getDate() + 60)).toLocaleDateString();
         }
         else {
            return new Date(new Date().setDate(new Date().getDate() + 60)).toLocaleDateString();
         }
      }
   }

   function getAccessorialGuideValue(overrides, accessorialGuide, accessorialName) {
      if (!accessorialGuide) {
         return;
      }

      if (!overrides.accessorialGuide) {
         return accessorialGuide.value;
      }

      const overrideValue = _.find(overrides.accessorialGuide, (x) => x.name === accessorialName);

      if (overrideValue) {
         return overrideValue.value;
      }

      return accessorialGuide.value;
   }

   function checkIfOverridden(overrides, accessorialName, value) {
      if (!overrides.accessorialGuide) {
         return false;
      }

      const overrideValue = _.find(overrides.accessorialGuide, (x) => (x.name === accessorialName && x.value != value));
      if (overrideValue) {
         return true;
      }

      return false;
   }

   // #region React View
   return (
      <>
         {loading ? (
            <div className="loader-wrapper">
               <div className="truck-wrapper">
                  <div className="truck">
                     <div className="truck-container">
                        <img style={{ width: "65px", paddingLeft: "5px", paddingTop: "15px" }} src={containerLogo} />
                     </div>
                     <div className="glases"></div>
                     <div className="bonet"></div>
                     <div className="base"></div>
                     <div className="base-aux"></div>
                     <div className="wheel-back"></div>
                     <div className="wheel-front"></div>
                     <div className="smoke"></div>
                  </div>
               </div>
            </div>
         ) : (
            <>
               <div id={"ordersNew"} className={classes.layoutMain}>
                  <div id={"ordersNewHeader"} className={classes.secondaryHeader}>
                     <Grid container direction="rows" alignItems="center">
                        <Grid item xs={4}>
                           {!loading && (
                              <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                                 <Link
                                    color="inherit"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => breadcrumbOnClick("/orders")}
                                 >
                                    <h3 style={{ fontWeight: "500" }}>{"Quotes"}</h3>
                                 </Link>
                                 <Link
                                    color="inherit"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => breadcrumbOnClick("/orders/new")}
                                 >
                                    <h3 style={{ fontWeight: "500" }}>{"New Quote"}</h3>
                                 </Link>
                                 {props.match.params.orderid ? (
                                    <Link
                                       color="inherit"
                                       style={{ cursor: "pointer" }}
                                       onClick={() =>
                                          breadcrumbOnClick("/orders/details/" + props.match.params.orderid)
                                       }
                                    >
                                       <h3 style={{ fontWeight: "500" }}>
                                          {newOrder.orderNumber ? newOrder.orderNumber : null}
                                       </h3>
                                    </Link>
                                 ) : null}
                              </Breadcrumbs>
                           )}
                        </Grid>
                        <Grid item xs={4}>
                           <Autocomplete
                              id="combo-box-customer"
                              options={props.customers}
                              onChange={(e, values) => onCustomerChange(values)}
                              value={quoteSearch.selectedCustomer}
                              getOptionLabel={(option) => {
                                 return customerCodeFormatter(option.code) + " | " + option.name;
                              }}
                              style={{ backgroundColor: "white" }}
                              renderInput={(params) => (
                                 <TextField {...params} label="Select Customer" variant="outlined" />
                              )}
                              autoSelect={true}
                              disabled={props.match.params.orderid || readOnly ? true : false}
                           />
                        </Grid>
                        <div>
                           {!showDetails ? (
                              <Grid
                                 item
                                 xs={4}
                                 style={{ marginRight: "1rem", marginLeft: "12rem", display: "inline" }}
                                 justifyContent="flex-end"
                              >
                                 <IconButton variant="contained" color="primary" disabled={true} size="large">
                                    <SaveIcon style={{ fontSize: "1.5rem" }}></SaveIcon>
                                 </IconButton>

                                 <IconButton variant="contained" color="primary" disabled={true} size="large">
                                    <FindInPageIcon style={{ fontSize: "1.5rem" }}></FindInPageIcon>
                                 </IconButton>

                                 <IconButton variant="contained" color="primary" disabled={true} size="large">
                                    <GetAppIcon style={{ fontSize: "1.5rem" }}></GetAppIcon>
                                 </IconButton>
                                 <IconButton variant="contained" color="primary" disabled={true} size="large">
                                    <EmailIcon style={{ fontSize: "1.5rem" }}></EmailIcon>
                                 </IconButton>

                              </Grid>
                           ) : (
                              <Grid
                                 item
                                 xs={4}
                                 style={{ marginRight: "1rem", marginLeft: "12rem", display: "inline" }}
                                 justifyContent="flex-end"
                              >
                                 <Tooltip title="Save Quote" arrow>
                                    <IconButton
                                       variant="contained"
                                       color="primary"
                                       size="large"
                                       disabled={
                                          buttonClicked || selectedOrderStatus === "COMPLETED" || readOnly
                                             ? true
                                             : false
                                       }
                                       onClick={() => saveOrder("SAVE")}
                                    >
                                       {saveLoading ? (
                                          <CircularProgress size={24} className={classes.buttonProgress} />
                                       ) : (
                                          <SaveIcon style={{ fontSize: "1.5rem" }} />
                                       )}
                                    </IconButton>
                                 </Tooltip>
                                 <Tooltip title="Preview Quote" arrow>
                                    <IconButton
                                       variant="contained"
                                       color="primary"
                                       disabled={buttonClicked}
                                       size="large"
                                       onClick={() => previewOrder()}
                                    >
                                       {savePreviewLoading ? (
                                          <CircularProgress size={24} className={classes.buttonProgress} />
                                       ) : (
                                          <FindInPageIcon style={{ fontSize: "1.5rem" }} />
                                       )}
                                    </IconButton>
                                 </Tooltip>
                                 <Tooltip
                                    title={
                                       selectedOrderStatus === "COMPLETED"
                                          ? "Download Quote"
                                          : "Complete Quote & Download"
                                    }
                                    arrow
                                 >
                                    <IconButton
                                       variant="contained"
                                       color="primary"
                                       size="large"
                                       disabled={buttonClicked || readOnly}
                                       onClick={() => saveOrder("DOWNLOAD")}
                                    >
                                       {saveAndDownloadLoading ? (
                                          <CircularProgress size={24} className={classes.buttonProgress} />
                                       ) : (
                                          <GetAppIcon style={{ fontSize: "1.5rem" }} />
                                       )}
                                    </IconButton>
                                 </Tooltip>
                                 <Tooltip
                                    title={
                                       selectedOrderStatus === "COMPLETED" ? "Email Quote" : "Complete Quote & Email"
                                    }
                                    arrow
                                 >
                                    <IconButton
                                       variant="contained"
                                       color="primary"
                                       size="large"
                                       disabled={buttonClicked || readOnly}
                                       onClick={() => saveOrder("EMAIL")}
                                    >
                                       {saveAndSendLoading ? (
                                          <CircularProgress size={24} className={classes.buttonProgress} />
                                       ) : (
                                          <EmailIcon style={{ fontSize: "1.5rem" }} />
                                       )}
                                    </IconButton>
                                 </Tooltip>
                              </Grid>
                           )}
                        </div>
                     </Grid>
                     <Collapse in={showAlert}>
                        <Alert
                           style={{ color: "#FFFFFF" }}
                           variant="filled"
                           severity={alertType ? alertType : "success"}
                           action={
                              <IconButton
                                 id="btn-alertClose"
                                 aria-label="close"
                                 color="inherit"
                                 size="small"
                                 onClick={() => {
                                    closeAlertMessage();
                                 }}
                                 style={{ marginBottom: ".25rem" }}
                              >
                                 <CloseIcon />
                              </IconButton>
                           }
                        >
                           {alertMessage}
                        </Alert>
                     </Collapse>
                     <Collapse in={open}>
                        <BootstrapDialog
                           //  onClose={handleClose}
                           aria-labelledby="customized-dialog-title"
                           open={open}
                        >
                           <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{
                              backgroundColor: "rgb(0, 45, 114)",
                              color: "white"
                           }}>
                              Duplicate Quote!
                           </DialogTitle>
                           <Tooltip title="Dismiss" arrow>
                              <IconButton
                                 aria-label="close"
                                 onClick={handleCloseQuote}
                                 style={{ color: "white" }}
                                 sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    //  color: (theme) => theme.palette.grey[500],
                                 }}
                              >
                                 <CloseIcon />
                              </IconButton></Tooltip>
                           <DialogContent >
                              <Grid container spacing={2}>
                                 <Grid item>
                                    <WarningAmberRoundedIcon style={{ fontSize: "100px", color: "rgb(0, 45, 114)" }}
                                    />
                                 </Grid>
                                 <Grid item xs={12} sm container>
                                    <Grid item xs container direction="column" spacing={2}>
                                       <Grid item xs>
                                          <Typography gutterBottom variant="subtitle1" component="div">
                                             There is already a quote for this customer in this lane. {!props.user.security.admin && <>Please reach out to <a href="mailto:quote@containerport.com">quote@containerport.com</a> for an updated quote.</>}
                                          </Typography>
                                       </Grid>
                                       <Grid container justifyContent="flex-end">
                                          <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                             <Tooltip
                                                title="View Existing Quote"
                                                arrow
                                                placement="top-end"
                                             >
                                                <Button onClick={viewExistingQuote} variant="contained" size="small"
                                                   style={{ textTransform: 'none', fontSize: 14 }}
                                                   startIcon={<VisibilityRoundedIcon />}
                                                > Existing Quote</Button></Tooltip>
                                             {props.user.security.admin ? <Tooltip
                                                title="Create Anyway"
                                                arrow
                                                placement="top-end"
                                             >  <Button
                                                startIcon={<NoteAddRoundedIcon />}
                                                style={{ textTransform: 'none', fontSize: 14 }}
                                                onClick={() => { searchForRate() }} variant="outlined" size="small">Create Anyway</Button></Tooltip> : null}
                                          </Typography>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </DialogContent>
                        </BootstrapDialog>
                     </Collapse>
                  </div>
                  <div id={"ordersNewBody"} className={showAlert === true ? classes.layoutBody2 : classes.layoutBody}>
                     <Grid container direction={"column"} justifyContent="space-between">
                        <div style={{ paddingBottom: "1%", width: "100%", margin: "auto" }}>
                           <Paper elevation={3} style={{ padding: "3%", paddingTop: "1%" }}>
                              <Grid
                                 container
                                 xs={10}
                                 direction={"row"}
                                 style={{
                                    placeContent: "space-evenly",
                                    marginLeft: "-2rem",
                                    padding: "2%",
                                    margin: "auto",
                                 }}
                              >
                                 <Grid item xs={5}>
                                    <Autocomplete
                                       id="combo-box-terminal"
                                       options={_.filter(props.terminals, (x) => x.active === true)}
                                       getOptionLabel={(option) => {
                                          return option.code + " | " + option.name;
                                       }}
                                       onChange={(e, values) => onTerminalChange(values)}
                                       value={quoteSearch.selectedTerminal}
                                       renderInput={(params) => (
                                          <TextField {...params} label="Select Terminal" variant="outlined" />
                                       )}
                                       autoSelect={true}
                                       disabled={props.match.params.orderid || readOnly ? true : false}
                                    />
                                 </Grid>
                                 <Grid item xs={2} style={{ textAlignLast: "center", alignSelf: "center" }}>
                                    <img src={TruckArrow}></img>
                                 </Grid>
                                 <Grid item xs={5}>
                                    <PlaceSearch
                                       onChange={(e, values) => onDestinationChange(e, values)}
                                       destination={
                                          (quoteSearch.destination.city, quoteSearch.destination.state)
                                             ? quoteSearch.destination.city + ", " + quoteSearch.destination.state
                                             : null
                                       }
                                       onKeyUp={onKeyUp}
                                       disabled={props.match.params.orderid || readOnly ? true : false}
                                    />
                                 </Grid>
                              </Grid>
                           </Paper>

                           {(!showDetails && quoteSearch.destination.city && quoteSearch.selectedTerminal && quoteSearch.selectedCustomer && (alertMessage != null)) ? <LinearProgress /> : <Divider style={{ marginBottom: "1rem" }} />}
                           {showDetails && props.user.security.admin ? <Paper
                              elevation={3}
                              style={{ marginBottom: "1%" }}
                              variant="outlined"
                           >
                              <Accordion
                                 expanded={props.user.security.admin ? expandedValidity === 'panel1' : false}
                                 onChange={handleChangeValidity('panel1')}
                              >
                                 <AccordionSummary
                                    expandIcon={props.user.security.admin && <ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                 >
                                    <Typography sx={{ width: '10%', flexShrink: 0, fontWeight: 'bold' }}>
                                       Validity
                                    </Typography>
                                    {expandedValidity ? null : <Typography sx={{ color: 'text.secondary' }}>Quote validity is from {getValidityDate('validFrom', validFrom)} till {getValidityDate('validTill', validTill)}</Typography>}
                                 </AccordionSummary>
                                 <AccordionDetails>
                                    <Typography>
                                       <Grid container spacing={2}>
                                          <Grid item xs={6}>
                                             <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={defaultDayjs}>
                                                <DatePicker
                                                   label="Valid From"
                                                   value={getValidityDate('validFrom', validFrom)}
                                                   name="validFrom"
                                                   onChange={(event) =>
                                                      handleChange(event, "validFrom")
                                                   }
                                                   renderInput={(params) => (
                                                      <TextField {...params}
                                                         style={{ width: "100%" }}
                                                      />
                                                   )}
                                                />
                                             </LocalizationProvider>

                                          </Grid>
                                          <Grid item xs={6}>

                                             <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={defaultDayjs}>
                                                <DatePicker
                                                   label="Valid Till"
                                                   value={getValidityDate('validTill', validTill)}
                                                   name="validTill"
                                                   onChange={(event) => handleChange(event, "validTill")}
                                                   renderInput={(params) => (
                                                      <TextField {...params}
                                                         style={{ width: "100%" }}
                                                      />

                                                   )}
                                                   minDate={
                                                      new Date(new Date(getValidityDate('validFrom', validFrom)).setDate(new Date(getValidityDate('validFrom', validFrom)).getDate() + 1)).toLocaleDateString()
                                                   }
                                                />
                                             </LocalizationProvider>
                                          </Grid></Grid>

                                    </Typography>
                                 </AccordionDetails>
                              </Accordion></Paper> : null}
                           <div style={{ paddingBottom: "1%", width: "100%", display: showDetails ? "block" : "none" }}>
                              {newOrder.items.map((item, index) => {
                                 return (
                                    <Slide
                                       key={`s-${index}`}
                                       direction="up"
                                       in={showDetails ? true : false}
                                       mountOnEnter
                                       unmountOnExit
                                    >
                                       <Paper
                                          elevation={3}
                                          style={{ padding: "3%", paddingTop: "1%", marginBottom: "1%" }}
                                          variant="outlined">
                                          <div
                                             style={{
                                                width: "100%",
                                                display: newOrder.items.length > 1 ? "block" : "none",
                                                background: "#002D72",
                                             }}
                                          >
                                             <Grid item xs={12} background="#002D72">
                                                <Grid
                                                   container
                                                   direction={"column"}
                                                   xs={12}
                                                   style={{ height: "max-content" }}
                                                >
                                                   <Grid
                                                      container
                                                      direction={"row"}
                                                      xs={12}
                                                      style={{ height: "max-content", color: "#ffffff" }}
                                                   >
                                                      <Grid item xs={5}>
                                                         <h4 style={{ paddingLeft: "1rem" }}>
                                                            {_handleObjectProperty(
                                                               _.find(props.terminals, (terminal) => {
                                                                  return terminal._id === item.terminal_id;
                                                               }),
                                                               "name",
                                                            )}
                                                         </h4>
                                                      </Grid>
                                                      <Grid item xs={2}>
                                                         <img
                                                            style={{ paddingTop: "1.5rem" }}
                                                            src={TruckArrowWhite}
                                                         ></img>
                                                      </Grid>
                                                      <Grid item xs={5}>
                                                         <h4 style={{ paddingLeft: "1rem" }}>
                                                            {item.rate.destination.city +
                                                               ", " +
                                                               item.rate.destination.state +
                                                               " " +
                                                               item.rate.destination.zip}
                                                         </h4>
                                                      </Grid>
                                                   </Grid>
                                                </Grid>
                                             </Grid>
                                             <Divider style={{ marginBottom: "1rem" }} />
                                          </div>
                                          <Grid item xs={12}>
                                             <Grid container direction={"row"} xs={12}>
                                                <Grid
                                                   container
                                                   direction={"column"}
                                                   xs={12}
                                                   style={{ height: "max-content" }}
                                                >
                                                   <Grid container direction={"row"} xs={12}>
                                                      <Grid item xs={2}>
                                                         <h4 style={{ paddingLeft: "1rem" }}>Rate</h4>
                                                      </Grid>
                                                      <Grid item xs={2}>
                                                         <h4 style={{ paddingLeft: "1rem" }}>Fuel</h4>
                                                      </Grid>
                                                      <Grid item xs={2}>
                                                         <h4 style={{ paddingLeft: "0.75rem" }}>Miles</h4>
                                                      </Grid>
                                                      <Grid item xs={3}>
                                                         <h4>{`Tariff: ${getTariffLabel(item)}`}</h4>
                                                      </Grid>
                                                      <Grid item xs={6}>
                                                         <Tooltip title="Reset Rate and Fuel" arrow>
                                                            <IconButton
                                                               tabIndex="-1"
                                                               variant="contained"
                                                               color="primary"
                                                               style={{
                                                                  marginLeft: "1rem",
                                                                  float: "right",
                                                                  marginRight: "1rem",
                                                               }}
                                                               onClick={() => resetFuel(index)}
                                                               disabled={loading || readOnly}
                                                            >
                                                               <RotateLeftIcon style={{ fontSize: "2rem" }} />
                                                            </IconButton>
                                                         </Tooltip>
                                                      </Grid>
                                                   </Grid>
                                                   <Grid item xs={12}>
                                                      <div>
                                                         <TextField
                                                            id="tf-baseRate"
                                                            style={{ margin: 15 }}
                                                            variant="outlined"
                                                            inputProps={inputPropsWhole}
                                                            name="base_rate"
                                                            onChange={(event) =>
                                                               onOverrideChange(
                                                                  item.overrides,
                                                                  index,
                                                                  "base_rate",
                                                                  event,
                                                               )
                                                            }
                                                            type="number"
                                                            value={
                                                               item.overrides.base_rate
                                                                  ? item.overrides.base_rate
                                                                  : item.overrides.base_rate === 0
                                                                     ? item.overrides.base_rate
                                                                     : item.rate.rateInfo
                                                                        ? item.rate.rateInfo.base_rate
                                                                           ? calcCPGrate(
                                                                              item.rate.rateInfo.base_rate,
                                                                              item.rate.rateInfo.tolls,
                                                                              item.rate.rateInfo.bobtail,
                                                                           )
                                                                           : 0
                                                                        : 0
                                                            }
                                                            error={
                                                               item.overrides.base_rate
                                                                  ? true
                                                                  : item.overrides.base_rate === 0
                                                                     ? true
                                                                     : false
                                                            }
                                                            disabled={
                                                               selectedOrderStatus === "COMPLETED" || readOnly
                                                                  ? true
                                                                  : false
                                                            }
                                                         />
                                                         <TextField
                                                            id="tf-dpm"
                                                            style={{
                                                               margin: 15,
                                                               display: !item.fuelTariff.tariffInfo.cpm ? "none" : null,
                                                            }}
                                                            label="DPM"
                                                            variant="outlined"
                                                            inputProps={inputProps}
                                                            name="cpm"
                                                            onChange={(event) =>
                                                               onOverrideChange(item.overrides, index, "cpm", event)
                                                            }
                                                            type="number"
                                                            value={
                                                               item.overrides.cpm
                                                                  ? item.overrides.cpm
                                                                  : item.overrides.cpm === 0
                                                                     ? item.overrides.cpm
                                                                     : item.fuelTariff.tariffInfo.cpm
                                                                        ? item.fuelTariff.tariffInfo.cpm
                                                                        : 0
                                                            }
                                                            error={
                                                               item.overrides.cpm
                                                                  ? true
                                                                  : item.overrides.cpm === 0
                                                                     ? true
                                                                     : false
                                                            }
                                                            disabled={
                                                               (props.user.security.overRideFuel ||
                                                                  props.user.security.admin) &&
                                                                  !readOnly &&
                                                                  selectedOrderStatus !== "COMPLETED"
                                                                  ? false
                                                                  : true
                                                            }
                                                         />
                                                         <TextField
                                                            id="tf-ppr"
                                                            style={{
                                                               margin: 15,
                                                               display: !item.fuelTariff.tariffInfo.ppr ? "none" : null,
                                                            }}
                                                            label="PPR"
                                                            variant="outlined"
                                                            inputProps={inputProps}
                                                            name="ppr"
                                                            onChange={(event) =>
                                                               onOverrideChange(item.overrides, index, "ppr", event)
                                                            }
                                                            type="number"
                                                            value={
                                                               item.overrides.ppr
                                                                  ? item.overrides.ppr
                                                                  : item.overrides.ppr === 0
                                                                     ? item.overrides.ppr
                                                                     : item.fuelTariff.tariffInfo.ppr
                                                                        ? item.fuelTariff.tariffInfo.ppr
                                                                        : 0
                                                            }
                                                            error={
                                                               item.overrides.ppr
                                                                  ? true
                                                                  : item.overrides.ppr === 0
                                                                     ? true
                                                                     : false
                                                            }
                                                            disabled={
                                                               (props.user.security.overRideFuel ||
                                                                  props.user.security.admin) &&
                                                                  !readOnly &&
                                                                  selectedOrderStatus !== "COMPLETED"
                                                                  ? false
                                                                  : true
                                                            }
                                                         />
                                                         <TextField
                                                            id="tf-miles"
                                                            style={{ margin: 15, backgroundColor: "#e0e0e0" }}
                                                            variant="outlined"
                                                            inputProps={inputPropsWhole}
                                                            name="miles"
                                                            type="number"
                                                            value={
                                                               item.rate.rateInfo
                                                                  ? item.rate.rateInfo.miles
                                                                     ? item.rate.rateInfo.miles
                                                                     : 0
                                                                  : 0
                                                            }
                                                            disabled={true}
                                                         />
                                                      </div>
                                                   </Grid>
                                                   <Divider style={{ marginRight: "16px" }} />
                                                   <Grid item xs={12}>
                                                      <Accordion
                                                         tabIndex="-1"
                                                         expanded={item.expanded}
                                                         onChange={(event) => onExpandedChange(index, event)}
                                                      >
                                                         <Grid
                                                            container
                                                            direction={"column"}
                                                            xs={12}
                                                            style={{ height: "max-content" }}
                                                         >
                                                            <Grid container direction={"row"} xs={12}>
                                                               <Grid item xs={9}>
                                                                  <h4 style={{ paddingLeft: "1rem" }}>
                                                                     Accessorial
                                                                  </h4>
                                                               </Grid>
                                                               <Grid item xs={1}>
                                                                  <AccessorialVerbiage
                                                                     localAccessorialGuides={item?.accessorialTariff?.accessorialGuide || []}
                                                                     accessorialGuides={props?.accessorialGuides || []}
                                                                     key={`s-${index}`}
                                                                     setAccessorialComment={setAccessorialComment}
                                                                     setOverrideAccessorialComment={setOverrideAccessorialComment}
                                                                     overrideAccessorialComment={overrideAccessorialComment}
                                                                     accessorialTariffs={props?.accessorialTariffs.find(x => x?._id === quoteSearch?.selectedTerminal?.accessorialTariff_id) || []}
                                                                     customers={props?.customers.find(x => x?._id === quoteSearch?.selectedCustomer?._id) || []}
                                                                     quoteSearch={quoteSearch}
                                                                     disabled={
                                                                        (props.user.security.overRideFuel ||
                                                                           props.user.security.admin) &&
                                                                           !readOnly &&
                                                                           selectedOrderStatus !== "COMPLETED"
                                                                           ? false
                                                                           : true
                                                                     }
                                                                  />
                                                               </Grid>
                                                               <Grid item xs={1}>
                                                                  <Tooltip title="Reset Accessorial" arrow>
                                                                     <IconButton
                                                                        tabIndex="-1"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        style={{
                                                                           float: "right",
                                                                           marginTop: "22px"
                                                                        }}
                                                                        onClick={() => resetAccessorial(index)}
                                                                        disabled={loading || readOnly}
                                                                     >
                                                                        <RotateLeftIcon
                                                                           style={{ fontSize: "2rem" }}
                                                                        />
                                                                     </IconButton>
                                                                  </Tooltip></Grid>
                                                               <Grid item xs={1}>
                                                                  <AccordionSummary
                                                                     expandIcon={<ExpandMoreIcon />}
                                                                     style={{ marginTop: "1rem" }}
                                                                     id="accordionAccessorial"
                                                                  ></AccordionSummary>
                                                               </Grid>
                                                            </Grid>
                                                         </Grid>
                                                         <AccordionDetails>
                                                            <Grid
                                                               container
                                                               direction={"column"}
                                                               xs={12}
                                                               style={{ height: "max-content" }}
                                                            >
                                                               <Grid container direction={"row"} xs={12}>
                                                                  <Grid item xs={6}>
                                                                     {item.accessorialTariff.accessorialGuide.map(
                                                                        (guide) => (
                                                                           <TextField
                                                                              id={`tf-${guide.name}`}
                                                                              style={{ margin: 15 }}
                                                                              label={`${guide.name}`}
                                                                              variant="outlined"
                                                                              inputProps={inputProps}
                                                                              name={`${guide.name}`}
                                                                              onChange={(event) =>
                                                                                 onOverrideChange(
                                                                                    item.overrides,
                                                                                    index,
                                                                                    guide.name,
                                                                                    event,
                                                                                 )
                                                                              }
                                                                              type="number"
                                                                              value={getAccessorialGuideValue(
                                                                                 item.overrides,
                                                                                 guide,
                                                                                 guide.name,
                                                                              )}
                                                                              error={checkIfOverridden(
                                                                                 item.overrides,
                                                                                 guide.name,
                                                                                 guide?.value
                                                                              )}
                                                                              disabled={
                                                                                 (props.user.security.overRideAA ||
                                                                                    props.user.security.admin) &&
                                                                                    !readOnly &&
                                                                                    selectedOrderStatus !== "COMPLETED"
                                                                                    ? false
                                                                                    : true
                                                                              }
                                                                           />
                                                                        ),
                                                                     )}
                                                                  </Grid>
                                                                  <Grid item xs={6} style={{ height: "max-content" }}>
                                                                     <div
                                                                        style={{ width: "100%", height: "max-content" }}
                                                                     >
                                                                        <Grid container direction={"row"}>
                                                                           <Grid item xs={12}>
                                                                              <Box sx={{ width: '100%', typography: 'body1' }}>
                                                                                 <TabContext value={tabValue}>
                                                                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                                                       <TabList onChange={handleChangeTabValue} aria-label="lab API tabs example">
                                                                                          <Tab label={"Accessorial Value (" + `${item.overrideDetails ? finalOverrideDetails(item?.overrideDetails).length : 0}` + ")"} value="1" />
                                                                                          <Tab label={"Accessorial Veribiage (" + `${overrideAccessorialComment.length}` + ")"} value="2" />
                                                                                       </TabList>
                                                                                    </Box>
                                                                                    <TabPanel value="1"><TableContainer component={Paper}>
                                                                                       <div style={{ overflow: "auto" }}>
                                                                                          <Table>
                                                                                             <TableHead backgroundColor="#002D72">
                                                                                                <TableRow>
                                                                                                   <StyledTableCell>
                                                                                                      <h4
                                                                                                         style={{
                                                                                                            color: "white",
                                                                                                         }}
                                                                                                      >
                                                                                                         Item
                                                                                                      </h4>
                                                                                                   </StyledTableCell>
                                                                                                   <StyledTableCell align="right">
                                                                                                      <h4
                                                                                                         style={{
                                                                                                            color: "white",
                                                                                                         }}
                                                                                                      >
                                                                                                         Original
                                                                                                      </h4>
                                                                                                   </StyledTableCell>
                                                                                                   <StyledTableCell align="right">
                                                                                                      <h4
                                                                                                         style={{
                                                                                                            color: "white",
                                                                                                         }}
                                                                                                      >
                                                                                                         Override
                                                                                                      </h4>
                                                                                                   </StyledTableCell>
                                                                                                   <StyledTableCell align="right">
                                                                                                      <h4
                                                                                                         style={{
                                                                                                            color: "white",
                                                                                                         }}
                                                                                                      >
                                                                                                         Difference
                                                                                                      </h4>
                                                                                                   </StyledTableCell>
                                                                                                </TableRow>
                                                                                             </TableHead>
                                                                                          </Table>
                                                                                       </div>
                                                                                       <div
                                                                                          style={{
                                                                                             overflow: "auto",
                                                                                             height: "252px",
                                                                                          }}
                                                                                       >
                                                                                          <Table>
                                                                                             <TableBody>
                                                                                                {item.overrideDetails
                                                                                                   ?
                                                                                                   finalOverrideDetails(item.overrideDetails).map(
                                                                                                      (ovr) => (
                                                                                                         <TableRow
                                                                                                            key={ovr.item}
                                                                                                         >
                                                                                                            <TableCell>
                                                                                                               <h5>
                                                                                                                  {ovr.item}
                                                                                                               </h5>
                                                                                                            </TableCell>
                                                                                                            <TableCell align="right">
                                                                                                               {
                                                                                                                  +ovr.original.toFixed(
                                                                                                                     2,
                                                                                                                  )}
                                                                                                            </TableCell>
                                                                                                            <TableCell align="right">
                                                                                                               {
                                                                                                                  +ovr.override.toFixed(
                                                                                                                     2,
                                                                                                                  )
                                                                                                               }
                                                                                                            </TableCell>
                                                                                                            <TableCell align="right">
                                                                                                               {
                                                                                                                  +ovr.difference.toFixed(
                                                                                                                     2,
                                                                                                                  )
                                                                                                               }
                                                                                                               {(ovr.original >
                                                                                                                  ovr.override ? (
                                                                                                                  <ArrowDownwardIcon color="secondary" />
                                                                                                               ) : (
                                                                                                                  <ArrowUpwardIcon
                                                                                                                     style={{
                                                                                                                        color: green[500],
                                                                                                                     }}
                                                                                                                  />
                                                                                                               ))}
                                                                                                            </TableCell>
                                                                                                         </TableRow>
                                                                                                      ),
                                                                                                   )
                                                                                                   : null}
                                                                                             </TableBody>
                                                                                          </Table>
                                                                                       </div>
                                                                                    </TableContainer></TabPanel>
                                                                                    <TabPanel value="2"><TableContainer component={Paper}>
                                                                                       <div style={{ overflow: "auto" }}>
                                                                                          <Table>
                                                                                             <TableHead backgroundColor="#002D72">
                                                                                                <TableRow>
                                                                                                   <StyledTableCell>
                                                                                                      <h4
                                                                                                         style={{
                                                                                                            color: "white",
                                                                                                         }}
                                                                                                      >
                                                                                                         Items
                                                                                                      </h4>
                                                                                                   </StyledTableCell>
                                                                                                   <StyledTableCell align="right">
                                                                                                      <h4
                                                                                                         style={{
                                                                                                            color: "white",
                                                                                                         }}
                                                                                                      >
                                                                                                         Default
                                                                                                      </h4>
                                                                                                   </StyledTableCell>
                                                                                                   <StyledTableCell align="right">
                                                                                                      <h4
                                                                                                         style={{
                                                                                                            color: "white",
                                                                                                         }}
                                                                                                      >
                                                                                                         Customer
                                                                                                      </h4>
                                                                                                   </StyledTableCell>
                                                                                                   <StyledTableCell align="right">
                                                                                                      <h4
                                                                                                         style={{
                                                                                                            color: "white",
                                                                                                         }}
                                                                                                      >
                                                                                                         Terminal
                                                                                                      </h4>
                                                                                                   </StyledTableCell>
                                                                                                   <StyledTableCell align="right">
                                                                                                      <h4
                                                                                                         style={{
                                                                                                            color: "white",
                                                                                                         }}
                                                                                                      >
                                                                                                         Override
                                                                                                      </h4>
                                                                                                   </StyledTableCell>

                                                                                                </TableRow>
                                                                                             </TableHead>
                                                                                          </Table>
                                                                                       </div>
                                                                                       <div
                                                                                          style={{
                                                                                             overflow: "auto",
                                                                                             height: "252px",
                                                                                          }}
                                                                                       >
                                                                                          <Table>
                                                                                             <TableBody>
                                                                                                {overrideAccessorialComment.map(
                                                                                                   (ovr) => (
                                                                                                      <TableRow
                                                                                                         key={ovr._id}
                                                                                                      >
                                                                                                         <TableCell>
                                                                                                            <h5>
                                                                                                               {ovr?.name + (ovr?.status === "UPDATED" ?
                                                                                                                  "*" : "")}
                                                                                                            </h5>
                                                                                                         </TableCell>
                                                                                                         <TableCell align="right">
                                                                                                            {
                                                                                                               ovr?.pdfVerbiageDefaultLevel || "-"
                                                                                                            }
                                                                                                         </TableCell>
                                                                                                         <TableCell align="right">
                                                                                                            {
                                                                                                               ovr?.pdfVerbiageCustomerLevel || "-"
                                                                                                            }
                                                                                                         </TableCell>

                                                                                                         <TableCell align="right">
                                                                                                            {
                                                                                                               ovr?.pdfVerbiageTerminalLevel || "-"
                                                                                                            }
                                                                                                         </TableCell>

                                                                                                         <TableCell align="right">
                                                                                                            {
                                                                                                               ovr?.pdfVerbiage || "-"
                                                                                                            }
                                                                                                         </TableCell>

                                                                                                      </TableRow>
                                                                                                   ),
                                                                                                )
                                                                                                }
                                                                                             </TableBody>
                                                                                          </Table>
                                                                                       </div>
                                                                                    </TableContainer></TabPanel>
                                                                                 </TabContext>
                                                                              </Box>
                                                                           </Grid>
                                                                        </Grid>
                                                                     </div>
                                                                  </Grid>
                                                               </Grid>
                                                            </Grid>
                                                         </AccordionDetails>
                                                      </Accordion>
                                                      <Divider style={{ marginRight: "16px" }} />
                                                   </Grid>
                                                </Grid>
                                             </Grid>
                                          </Grid>
                                       </Paper>
                                    </Slide>
                                 );
                              })}
                           </div>
                        </div>
                     </Grid>
                  </div>
               </div>
            </>
         )}
         <OrdersNewEmail
            open={ordersNewEmailModal}
            handleClose={() => setOrdersNewEmailModal(!ordersNewEmailModal)}
            openAlertMessage={openAlertMessage}
            newOrderResponse={newOrderResponse}
         />
      </>
   );
};
// #endregion

// #region Redux Stores
function mapStateToProps(state) {
   return {
      terminals: state.terminal.terminals || [],
      customers: state.customer.customer || [],
      fuelTariffs: state.fuel.tariff || [],
      accessorialTariffs: state.accessorial.tariff || [],
      accessorialGuides: state.accessorialGuide.accessorialGuide || [],
      user: state.user.currentUser,
   };
}
// #endregion

// #region Prop Types
OrdersNew.propTypes = {
   match: PropTypes.shape({
      params: PropTypes.shape({
         orderid: PropTypes.string,
      }),
   }),
   accessorialTariffs: PropTypes.array.isRequired,
   accessorialGuides: PropTypes.array.isRequired,
   customers: PropTypes.array.isRequired,
   fuelTariffs: PropTypes.array.isRequired,
   terminals: PropTypes.array.isRequired,
   user: PropTypes.object.isRequired,
   openAlertMessage: PropTypes.func.isRequired,
};

OrdersNew.defaultProps = {
   accessorialTariffs: [],
   accessorialGuides: [],
   customers: [],
   fuelTariffs: [],
   terminals: [],
   user: {},
   openAlertMessage: () => {
      return;
   },
};
// #endregion

export default connect(mapStateToProps)(OrdersNew);
